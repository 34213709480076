import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogDetailsIfNeeded } from '@/redux/modules/catalog/detail/catalogDetail.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { getIdFromPath } from '@/utils/urls';

export default ({ location, store }: DataLoaderParams) => {
    const { dispatch } = store;
    const { pathname } = location;
    const catalogId = getIdFromPath(pathname);
    return Promise.all([
        dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] })),
        dispatch(fetchCatalogDetailsIfNeeded([catalogId])),
    ]);
};
