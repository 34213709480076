import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchCatalogRegistrationsForUserByIdIfNeeded } from '@/redux/modules/catalog/registration/catalogRegistration.actions';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchSavedItems } from '@/redux/modules/item/saved/savedItems.actions';
import { getSavedItemCatalogIds, getSavedItemsItemIds } from '@/redux/modules/item/saved/savedItems.selectors';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { MyFavoritesPageQueryParams } from '../types/MyFavoritesPage.types';
import { parseQueryParams } from '@/utils/queryParams';
import { toQueryString } from '@/utils/urls';
import validateMyFavoritesPageParams from './validateMyFavoritesPageParams';

const myFavoritesPageData = async ({ location, redirect, store }: DataLoaderParams) => {
    const params = parseQueryParams<MyFavoritesPageQueryParams>(location.search);
    const { dispatch, getState } = store;

    const userIsLoggedIn = isUserLoggedIn(getState());

    // Prompt user to log in and return to this page
    if (!userIsLoggedIn) {
        return redirect(
            `/loginRedirect?${toQueryString({
                redirectTo: `${location.pathname}${location.search || ''}`,
            })}`
        );
    }

    // get items for page
    await dispatch(fetchSavedItems(validateMyFavoritesPageParams(params)));
    const savedItemCatalogIds = getSavedItemCatalogIds(getState());
    const savedItemIds = getSavedItemsItemIds(getState());

    await Promise.all([
        // get catalog registrations for items
        dispatch(
            fetchCatalogRegistrationsForUserByIdIfNeeded({
                catalogIds: savedItemCatalogIds,
            })
        ),
        // get the ad for page
        dispatch(fetchCatalogAdIfNeeded({ pageName: 'leaderboard-favorites-page' })),
        dispatch(
            fetchItemSummariesIfNeeded({
                force: true,
                identifier: 'saved-item',
                itemIds: savedItemIds,
            })
        ),
    ]);
};

export default myFavoritesPageData;
