import * as React from 'react';
import { CustomTheme } from '@/theme/declaration';
import { darken, lighten, toHex } from 'color2k';
import { getIsBot } from '@/redux/modules/analytics';
import { getIsBrowserOutdated } from '@/redux/modules/browser';
import { getIsWhiteLabel, getWhiteLabelPrimaryColor } from '@/redux/modules/whiteLabel';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { LinkProvider } from '@liveauctioneers/hammer-ui-core/providers';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from '@liveauctioneers/hammer-ui-core/toast';
import { useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationChangeListener } from '@/pages/App/hooks/useLocationChangeListener';
import AppJSX from './AppJSX';
import Auth from './Auth';
import BrowserOutdatedBanner from '@/components/BrowserOutdatedBanner/BrowserOutdatedBanner';
import DismissableGetMobileBanner from '@/components/DismissableGetMobileBanner/DismissableGetMobileBanner';
import DismissableGetWebNotificationsBanner from '@/components/DismissableGetWebNotificationsBanner/DismissableGetWebNotificationsBanner';
import FeedbackWidgetContainer from '@/components/FeedbackWidgetContainer/FeedbackWidgetContainer';
import ForgotPasswordAction from '@/components/Modals/AuthModal/pages/ForgotPassword/ForgotPasswordAction';
import Hydrate from './Hydrate';
import loadable from '@loadable/component';
import NewSafariSentinel from '@/components/NewSafariSentinel/NewSafariSentinel';
// import OnboardingWalkthrough from '@/components/OnboardingWalkthrough/OnboardingWalkthrough';
import { GoogleAuthWidget } from './GoogleAuthWidget';
import Signifyd from '@/components/Signifyd/Signifyd';
import SignupAction from '@/components/Modals/SignUp/SignupAction';
import SuspendedBidderBanner from '@/components/SuspendedBidderBanner/SuspendedBidderBanner';
import TermsOfServiceBanner from '@/components/TermsOfServiceBanner/TermsOfServiceBanner';
import UnpaidInvoiceCheck from './UnpaidInvoiceCheck';

const OpenReplay = loadable(() => import('@/components/OpenReplay/OpenReplay'), { ssr: false });
const AmplitudeProvider = loadable(() => import('@/components/Analytics/AmplitudeProvider'), { ssr: false });

const BidderNotificationsSubscriber = loadable(
    () => import('@/components/LiveEventSubscribers/BidderNotificationsSubscriber'),
    {
        ssr: false,
    }
);

const GlobalNotificationsSubscriber = loadable(
    () => import('@/components/LiveEventSubscribers/GlobalNotificationsSubscriber'),
    {
        ssr: false,
    }
);

type Props = {
    children: React.ReactNode;
};

const App = ({ children }: Props) => {
    useLocationChangeListener();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { formatMessage } = useIntl();
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const isBiddingConsole = pathname.includes('/console/');
    const isAuthRedirect = pathname.includes('/auth/');
    const isCapital = pathname.includes('/capital');
    const isVideo = pathname.includes('/video/');
    const isCheckout = pathname.includes('/checkout/');
    const isCeckoutATG = pathname.includes('/checkoutATG/');
    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const whiteLabelPrimaryColor = useAppSelector(getWhiteLabelPrimaryColor);
    const isBrowserOutdated = useAppSelector(getIsBrowserOutdated);
    const isBot = useAppSelector(getIsBot);

    // https://styled-components.com/docs/advanced#function-themes
    const modifyThemeWithWhiteLabel = React.useCallback(
        (theme: CustomTheme) => {
            if (isWhiteLabel && whiteLabelPrimaryColor && whiteLabelPrimaryColor !== theme.colors.blue100) {
                return {
                    ...theme,
                    colors: {
                        ...theme.colors,
                        blue100: toHex(whiteLabelPrimaryColor),
                        blue200: toHex(darken(whiteLabelPrimaryColor, 0.1)),
                        blue300: toHex(lighten(whiteLabelPrimaryColor, 0.1)),
                    },
                };
            }

            return theme;
        },
        [isWhiteLabel, whiteLabelPrimaryColor]
    );

    const showHeaderAndFooter =
        !isBiddingConsole && !isCapital && !isVideo && !isCheckout && !isCeckoutATG && !isAuthRedirect;

    return (
        <React.StrictMode>
            <LinkProvider onLinkClick={navigate}>
                <OpenReplay />
                <AmplitudeProvider />
                <ToastContainer />
                <Signifyd />
                <Hydrate />
                {/* <OnboardingWalkthrough /> */}
                <FeedbackWidgetContainer />
                <UnpaidInvoiceCheck />
                {userLoggedIn && (
                    <>
                        <BidderNotificationsSubscriber />
                        <GlobalNotificationsSubscriber formatMessage={formatMessage} />
                        <DismissableGetWebNotificationsBanner />
                    </>
                )}
                <TermsOfServiceBanner />
                {isBrowserOutdated && !isBot && <BrowserOutdatedBanner />}
                {!isBiddingConsole && <DismissableGetMobileBanner />}
                <SuspendedBidderBanner />
                <NewSafariSentinel />
                <Auth />
                <GoogleAuthWidget />
                <SignupAction />
                <ForgotPasswordAction />
                {/* @ts-expect-error, since we're conditionally changing the values "as const" doesn't like it. */}
                <ThemeProvider theme={modifyThemeWithWhiteLabel}>
                    <AppJSX showHeaderAndFooter={showHeaderAndFooter}>{children}</AppJSX>
                </ThemeProvider>
            </LinkProvider>
        </React.StrictMode>
    );
};

export default React.memo(App);
