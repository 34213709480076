import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchRecentItemsIfNeeded, getLimitedSortedRecentItemIdsFromState } from '@/redux/modules/recentItem';

export default ({ store }: DataLoaderParams) => {
    const { dispatch, getState } = store;

    return dispatch(fetchRecentItemsIfNeeded()).then(() => {
        const state = getState();
        const itemIds = getLimitedSortedRecentItemIdsFromState(state, 72);
        return dispatch(
            fetchItemSummariesIfNeeded({
                identifier: 'RECENT-ITEMS-PAGE-DATA',
                itemIds,
            })
        );
    });
};
