import { ShippingPolicyTranslation } from '@/intl/partnerShippingPolicies/shippingPolicy.types';

export const nationalLogisticServices: ShippingPolicyTranslation = {
    policy:
        '<h2>Terms and Limits of Liability</h2>{newLine}' +
        '1. All item(s) to be packaged and/or shipped shall have a value declared. Value Coverage for each item(s) declared is available if the CUSTOMER agrees to pay for value coverage based on TrustNLS (TrustNLS) applicable rates and terms.{newLine}' +
        "2. In the absence of a declared value premium payment, TrustNLS's maximum liability for loss or damage for the item(s) shall be $50.00. Customer releases TrustNLS from any liability in excess of $50.00 in the absence of a declared value.{newLine}" +
        '3. Giving TrustNLS the items, goods, or property to package and/or ship, constitutes the agreement of the CUSTOMER to all of the terms of the Universal Shipping Agreement and Release (USAR). No one is authorized to change the terms of the USAR, and any change shall render the agreement void.{newLine}' +
        '4. <strong>LOST ITEMS:</strong> If the shipment, or any part thereof, does not arrive within 14 business days or specified timeframe, the CUSTOMER must notify TrustNLS. TrustNLS will then have an additional 14 business days to deliver the shipment before the CUSTOMER may claim that the shipment, or any part thereof, is lost. After that time has expired, the CUSTOMER may seek reimbursement from TrustNLS consistent with the limits outlined below.{newLine}' +
        '5. <strong>DAMAGED ITEMS:</strong> If there is damage to the shipment, all claims made are subject to and conditioned upon the following:{newLine}' +
        "<ListItem>a) Note such damages on the carrier's delivery document before signing. (Failure to do so may prevent the acceptance of any claim.)</ListItem>{newLine}" +
        '<ListItem>b) Retain ALL cartons, containers, packaging materials, and merchandise in the same condition as they arrived until further notice from TrustNLS. If possible, take and retain photographs of the shipment. Do not discard these materials until all claims are settled in full.</ListItem>{newLine}' +
        '<ListItem>c) Alert TrustNLS within 48 hours for further instructions.</ListItem>{newLine}' +
        '<ListItem>d) Do not release any damaged goods or containers to a carrier or its representatives without first contacting TrustNLS.</ListItem>{newLine}' +
        "6. <strong>VALUATION:</strong> The CUSTOMER acknowledges that they have been given the opportunity to declare a value for the items. TrustNLS's liability will in no event exceed the lesser of the following:{newLine}" +
        '<ListItem>a) Fifty dollars ($50) if no value is declared</ListItem>{newLine}' +
        '<ListItem>b) The actual cash market value of the damaged property at the point of distribution on the date of disaster</ListItem>{newLine}' +
        '<ListItem>c) The amount it would cost to repair or replace the damaged property with like kind or quality at the point of destination on the date of disaster</ListItem>{newLine}' +
        '<ListItem>d) The cash value declared for items within this agreement</ListItem>{newLine}' +
        '<ListItem>e) The total cash value declared, divided equally among the total number of items in the shipment (if 15 boxes are declared at $1500, each box is valued at $100). If the CUSTOMER has provided a detailed and separate inventory of all boxes, contents, and corresponding values, each box will be valued as stated on the inventory list.</ListItem>{newLine}' +
        "The cash market value of the damaged property shall be determined from the following: the original commercial invoice or receipt; a current internet site selling the same item or brand; an industry publication of similar item or brand; or a current appraisal (appraisal must be less than one year old). Proof of value must exist and be available prior to the shipment date. Declaring a value on this form is not an accepted proof of value, but does constitute CUSTOMER's agreement limiting the value of any and all claims for shipment damage or disappearance to the value so declared within the agreement.{newLine}" +
        '7. <strong>PROHIBITED ITEMS:</strong> TrustNLS prohibits shipment of items when that shipment would violate local law at the origin or destination of the shipment.{newLine}' +
        '8. <strong>PROHIBITED DESTINATIONS:</strong> TrustNLS prohibits shipments to destinations pursuant to the Sanctioned Destinations list put forth by the United States Bureau of Industry and Security.{newLine}' +
        '<strong>LIABILITY OF TrustNLS:</strong>{newLine}' +
        '9. If TrustNLS, makes a total loss payment, or pays the insured amount as shown on the valued inventory for a damaged item(s), they have the right to salvage the damaged article. TrustNLS reserves the right to inspect and verify all reported damages and to require substantiation of any claimed amounts or value of items.{newLine}' +
        '10. Liability is one way only from the Ship From to the Ship To (consignee) named in this agreement. There is no liability after the consignee accepts by opening or signing for the shipment, refuses the shipment, or the shipment is returned as undeliverable.{newLine}' +
        '11. Liability covers only the merchandise. TrustNLS is not liable for freight cost, carton and packaging material costs, CUSTOMER packaging costs or late delivery.{newLine}' +
        '12. In no event shall TrustNLS be liable for any damage, whether direct, indirect, consequential, incidental, special, depreciated, or loss of special value which may arise from any force majeure event, including, but not limited to acts of God such as rain, water, perils of the air, weather conditions, disappearance, non-delivery, delay of any shipment, public enemies, war, strikes, civil commotions, or acts of public authorities with accrual or apparent authority. This limitation shall apply to and include, but not be limited to damages, loss of profit, loss of income, or a loss of bargain.{newLine}' +
        '13. Under no circumstances will any claim be considered if received after 90 days from the date of delivery.{newLine}' +
        "14. In the event of any litigation arising out of this USAR, the prevailing party will be entitled to recover its costs and reasonable attorney's fees from the non-prevailing party. This contract will be interpreted in accordance with the laws of the state or province of TrustNLS.{newLine}" +
        '15. Additional charges may apply based on actual size, weight, packaging complexity, pick-up and delivery location conditions.{newLine}' +
        '16. TrustNLS is not liable for additional items collected on the pickup or any undisclosed items not listed in the agreement.{newLine}' +
        '17. TrustNLS may at their sole discretion, open and inspect any shipment and its contents at any time and reserves the right to refuse any goods. TrustNLS will not package, ship, or deliver prohibited items.{newLine}' +
        '18. Payment is due prior to the shipment leaving the location where it is packaged. If payment is not received within 5 days of notification of final charges, storage charges will apply.{newLine}' +
        '<strong>LIMITATIONS OF LIABILITY:</strong>{newLine}' +
        '19. CUSTOMER is responsible to confirm origin and destination addresses and contact information. If an address correction must be made by the carrier, the CUSTOMER agrees to pay for such service rather than have the shipment returned.{newLine}' +
        '20. Any and all liability hereunder is limited to the insured value stated on the USAR and/or documented on the accepted Estimate. For any item(s) not packed by TrustNLS, the item(s) can be covered for disappearance only, it/they will not be covered for damage.{newLine}' +
        '21. TrustNLS is not liable for internal damages to electronic or computer components without signs of visible damage to packaging materials and casings. Data files are not covered under any circumstance for erasure, corruption, or disappearance.{newLine}' +
        '22. If some items are made up of several individual components that comprise the working unit or finished goods as a whole and if an individual component is lost or damaged and it renders the complete unit non-functional or incomplete, the total value for the unit must be listed for each separate component or any claim will be settled under terms of this agreement.{newLine}' +
        '23. For artwork/display “items in case” TrustNLS is unable to check to secure the parts of the artwork inside the case. TrustNLS is only able to provide value coverage for any damage to the outside of the case and is unable to provide coverage for the items inside the case.{newLine}' +
        '24. TrustNLS is not liable/responsible for loss or damage for manufacturing defects and/or inherent vice items (including but not limited to hand-made oneof-a-kind items, carved wood, stone or marble, articles of antiquity, and stringed musical instruments), without signs of visible damage to packaging materials and containers.{newLine}' +
        '25. TrustNLS is not responsible for loss or damage to perishable or temperature controlled items to the extent the loss or damage results from exposure to heat or cold or the perishable nature of the product.{newLine}' +
        '26. In the event of cancellation of a job, a minimum $50.00 cancellation fee shall apply. Additional charges will apply if the item(s) have been picked up, packaged, shipped, or returned. Cancellations must be submitted in writing (email).{newLine}' +
        '27. TrustNLS is not liable for item(s) to be in working condition or to perform tests of working condition unless specifically stated as part of the services offered.{newLine}' +
        '28. Items lost or damaged that make up of a pair or set are limited to a pro-rata amount payment based on the value of each item. For example, the protected loss of one cufflink of a pair valued at $100.00 will result in a claim settlement of $50.00.{newLine}',
};
