import { MyFavoritesItemStatusTab, MyFavoritesPageQueryParams } from '../types/MyFavoritesPage.types';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

/**
 * Accepts all page params as an input, and returns an object of valid params to be used for data fetching
 *
 * This is intended to handle cases where the params are incorrect or missing
 */
const validateMyFavoritesPageParams = (params: MyFavoritesPageQueryParams): Required<MyFavoritesPageQueryParams> => {
    // create default values for api params (derived from url params)
    let keyword: MyFavoritesPageQueryParams['keyword'] = '';
    let page: MyFavoritesPageQueryParams['page'] = 1;
    let pageSize: MyFavoritesPageQueryParams['pageSize'] = 24;
    let sort: MyFavoritesPageQueryParams['sort'] = SearchSortAndDirection.TimeStartingSoonest;
    let status: MyFavoritesPageQueryParams['status'] = MyFavoritesItemStatusTab.Available;

    // if url params are present (and valid), use to fetch api
    if (params?.keyword && typeof params.keyword === 'string') {
        keyword = params.keyword;
    }

    if (params?.page && typeof params.page === 'number' && params.page > 0) {
        page = params.page;
    }

    if (params?.pageSize && typeof params.pageSize === 'number' && [24, 48, 72, 96, 120].includes(params.pageSize)) {
        pageSize = params.pageSize;
    }

    if (params?.status && Object.values(MyFavoritesItemStatusTab).includes(params.status)) {
        status = params.status;
    }

    if (params?.sort && Object.values(SearchSortAndDirection).includes(params.sort)) {
        sort = params.sort;
    } else {
        if (status === MyFavoritesItemStatusTab.Ended) {
            sort = SearchSortAndDirection.TimeNewest;
        }
    }

    return {
        keyword,
        page,
        pageSize,
        sort,
        status,
    };
};

export default validateMyFavoritesPageParams;
