import {
    AlgoliaMapBounds,
    AuctionNearMeHit,
    AuctionsNearMeRouteParams,
} from '@/pages/AuctionsNearMe/types/AuctionsNearMe.types';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import {
    defaultAuctionsNearMeUkBounds,
    defaultAuctionsNearMeUsaBounds,
    defaultHitsPerPage,
} from '@/pages/AuctionsNearMe/constants/AuctionsNearMeConstants';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchGeoAreaIfNeeded, loadAllGeoAreas } from '@/redux/modules/geoArea/geoArea';
import { getAlgoliaMapFilters } from '@/pages/AuctionsNearMe/helpers/getAlgoliaMapFilters';
import { populateAuctionsNearMeHits } from '@/redux/modules/auctionsNearMe/auctionsNearMe.reducer';
import algoliasearch from 'algoliasearch';

const auctionsNearMePageData = async ({ match, store }: DataLoaderParams<AuctionsNearMeRouteParams>) => {
    const geoAreaId = Number(match.params.geoAreaId || 0);
    const { dispatch, getState } = store;

    let mapBounds: AlgoliaMapBounds =
        getState().intl.locale.toLowerCase() === 'en-gb'
            ? defaultAuctionsNearMeUkBounds
            : defaultAuctionsNearMeUsaBounds;
    await dispatch(loadAllGeoAreas());
    await dispatch(fetchGeoAreaIfNeeded(geoAreaId));
    if (geoAreaId) {
        const geoArea = getState().geoArea.byId[geoAreaId];
        mapBounds = {
            northEast: {
                lat: geoArea.northEastLatitude,
                lng: geoArea.northEastLongitude,
            },
            southWest: {
                lat: geoArea.southWestLatitude,
                lng: geoArea.southWestLongitude,
            },
        };
    }

    const indexName = 'Available_Catalogs';
    const { algoliaApiKey, algoliaAppId } = getState().config;
    const client = algoliasearch(algoliaAppId, algoliaApiKey);
    const index = client.initIndex(indexName);
    const { hits } = await index.search<AuctionNearMeHit>('', {
        filters: getAlgoliaMapFilters(),
        hitsPerPage: defaultHitsPerPage,
        insideBoundingBox: [
            [mapBounds.northEast.lat, mapBounds.northEast.lng, mapBounds.southWest.lat, mapBounds.southWest.lng],
        ],
    });
    const catalogIds = hits.map(({ Catalog }) => Catalog.ID);
    await Promise.all([
        dispatch(populateAuctionsNearMeHits(hits)),
        dispatch(fetchCatalogsIfNeeded({ catalogIds })),
        dispatch(fetchCatalogAdIfNeeded({ pageName: 'leaderboard-auctions-near-me' })),
    ]);
};

export default auctionsNearMePageData;
