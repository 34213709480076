import { ConversationQueryType } from '@/redux/modules/conversation/conversations/conversation.types';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchConversationFoldersIfNeeded } from '@/redux/modules/conversation/folders/conversationFolders.actions';
import { loadConversations } from '@/redux/modules/conversation/conversations/conversation.actions';
import { parseQueryParams } from '@/utils/queryParams';

export default ({ location, store }: DataLoaderParams) => {
    const params: ConversationQueryType = parseQueryParams(location.search);
    const { dispatch } = store;

    return Promise.all([
        dispatch(fetchConversationFoldersIfNeeded({})),
        dispatch(loadConversations(params)),
        dispatch(fetchCatalogAdIfNeeded({ pageName: 'leaderboard-messages' })),
    ]);
};
