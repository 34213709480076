import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchDataForItemPage } from '@/redux/modules/itemPage/itemPage.actions';
import { getIdFromPath, getNotFoundUrl } from '@/utils/urls';

export default async ({ location, match: { params }, redirect, referrer, store: { dispatch } }: DataLoaderParams) => {
    const itemId = getIdFromPath(params.itemId);

    if (!itemId) {
        return redirect(getNotFoundUrl());
    }

    return dispatch(fetchDataForItemPage({ itemId, location, redirect, referrer }));
};
