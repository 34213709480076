import { LocationChangeAction, ROUTER_LOCATION_CHANGE } from '@/types/router/routerLocationChange';
import { useAppDispatch } from '@/redux/hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useLocationChangeListener = () => {
    const dispatch = useAppDispatch();
    const { hash, key, pathname, search, state } = useLocation();

    useEffect(() => {
        const action: LocationChangeAction = {
            payload: {
                action: 'POP',
                location: {
                    hash: hash ?? '',
                    key: key ?? '',
                    pathname: pathname ?? '',
                    search: search ?? '',
                    state: state ?? '',
                },
            },
            type: ROUTER_LOCATION_CHANGE,
        };
        dispatch(action);
    }, [hash, pathname, search, state, dispatch, key]);
};
