import { catalogSortOrders } from '@/enums';
import { changeSellerPagePast, changeSellerSearchTermsPast, getSellerViewData } from '@/redux/modules/viewData';
import {
    changeSellerPaginationSortOrder,
    getPaginationPageSize,
    getPaginationSortOrder,
} from '@/redux/modules/pagination';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import {
    fetchDataForSellerPagePast,
    fetchDataForSellerPageRecordResults,
    fetchDataForSellerPageUpcoming,
} from '@/redux/modules/sellerPage';
import { fetchLiveCatalogStatusIfNeeded } from '@/redux/modules/liveCatalogStatus';
import { fetchSellerFollowerCountIfNeeded } from '@/redux/modules/sellerFollowerCount';
import {
    fetchSellerLatestTopReviewsByIdIfNeeded,
    fetchSellerRatingSummaryByIdIfNeeded,
} from '@/redux/modules/sellerRatings';
import { fetchSellerPreviewCatalogsIfNeeded } from '@/redux/modules/sellerPreviewCatalogs';
import { fetchSellersDetailsIfNeeded } from '@/redux/modules/sellerDetail';
import { fetchSellersIfNeeded, getSellerName } from '@/redux/modules/seller';
import { fetchTopSellersIfNeeded } from '@/redux/modules/topRatedSellers';
import { fetchWonItemsForBannerIfNeeded } from '@/redux/modules/wonItems/wonItems.actions';
import { getIsWhiteLabel, getWhiteLabelSellerId } from '@/redux/modules/whiteLabel';
import { getNotFoundUrl } from '@/utils/urls';
import { getSellerUpcomingCatalogs } from '@/redux/modules/sellerUpcomingCatalogs';
import { parseQueryParams, validateQueryParams } from '@/utils/queryParams';

export default async ({ featureFlagVariants, location, match, redirect, store }: DataLoaderParams) => {
    const { params } = match;
    const queryParams = parseQueryParams(location.search);
    const { dispatch, getState } = store;
    const state = getState();

    const whiteLabel = getIsWhiteLabel(state);

    const sellerId = whiteLabel ? getWhiteLabelSellerId(state) : Number(params.sellerId);

    const {
        page: pageFromUrl,
        sort: sortFromUrl,
        terms: termsFromUrl,
    } = validateQueryParams(queryParams, catalogSortOrders);

    if (!sellerId) {
        return Promise.resolve();
    }

    const pageSize = getPaginationPageSize(state);
    const { page: pageFromState, searchTerms: termsFromState } = getSellerViewData(state, sellerId, 'upcoming');
    const sortId = 'seller-catalogs-past';
    const sortFromState = getPaginationSortOrder(state, sortId) || catalogSortOrders.DATE_DESCENDING;

    const promises = [
        dispatch(fetchSellersIfNeeded([sellerId])),
        dispatch(fetchSellersDetailsIfNeeded([sellerId])),
        dispatch(fetchSellerPreviewCatalogsIfNeeded(sellerId)),
        dispatch(fetchDataForSellerPageUpcoming(sellerId)),
        dispatch(fetchDataForSellerPagePast(sellerId, pageFromUrl, pageSize, termsFromUrl, sortFromState)),
        dispatch(fetchDataForSellerPageRecordResults(sellerId)),
        dispatch(fetchSellerFollowerCountIfNeeded([sellerId])),
        dispatch(fetchTopSellersIfNeeded()),
        dispatch(fetchSellerRatingSummaryByIdIfNeeded(sellerId)),
        dispatch(fetchSellerLatestTopReviewsByIdIfNeeded(sellerId)),
        dispatch(fetchWonItemsForBannerIfNeeded({ featureFlagVariants })),
    ];

    if (pageFromState !== pageFromUrl) {
        promises.push(dispatch(changeSellerPagePast(sellerId, pageFromUrl)));
    }
    if (sortFromState !== sortFromUrl) {
        promises.push(dispatch(changeSellerPaginationSortOrder(sortId, sortFromUrl)));
    }
    if (termsFromState !== termsFromUrl) {
        promises.push(dispatch(changeSellerSearchTermsPast(sellerId, termsFromUrl)));
    }

    await Promise.all(promises);
    const name = getSellerName(getState(), sellerId);

    // if we dont have a seller name then the fetch seller call failed and we should redirect to 404
    if (!name) {
        return redirect(getNotFoundUrl());
    }

    const timedPlusCatalogIds =
        getSellerUpcomingCatalogs(getState(), sellerId)
            ?.filter(({ isTimedPlus }) => isTimedPlus)
            ?.map(({ catalogId }) => catalogId) ?? [];
    return timedPlusCatalogIds.map((catalogId) => dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, sellerId)));
};
