import { buildSearchQuery } from '@/utils/search/searchQuery';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import {
    fetchDiscoverItemIdsIfNeeded,
    fetchShouldDiscoverTopicIfNeeded,
    getDiscoverItemIds,
} from '@/redux/modules/discover';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { getRawUpcomingSearchFacets } from '@/redux/modules/searchFacetAggregates.selectors';
import { parseQueryParams } from '@/utils/queryParams';

export default ({ location, match, store }: DataLoaderParams) => {
    const {
        params: { topic },
    } = match;
    const { pathname, search } = location;
    const { dispatch } = store;
    const queryParams = parseQueryParams(search);
    const { pageSize } = queryParams;
    const rawFacets = getRawUpcomingSearchFacets(store.getState());
    const keyword = (topic || '').split('-').join(' ');
    const searchQuery = buildSearchQuery({
        pathname,
        queryParams: { ...queryParams, keyword },
        rawFacets,
        searchPageSize: pageSize ? Number(pageSize) : 24,
    });

    return Promise.all([
        dispatch(fetchDiscoverItemIdsIfNeeded(searchQuery)),
        dispatch(fetchShouldDiscoverTopicIfNeeded(keyword)),
    ]).then(() => {
        const state = store.getState();
        const itemIds = getDiscoverItemIds(state);
        return Promise.all([
            dispatch(
                fetchItemSummariesIfNeeded({
                    identifier: 'DISCOVER-PAGE-DATA-LOADER',
                    itemIds,
                })
            ),
        ]);
    });
};
