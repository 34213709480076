import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchBidLimitBalanceIfNeeded } from '@/redux/modules/bidLimit';
import { fetchCatalogDetailsIfNeeded } from '@/redux/modules/catalog/detail/catalogDetail.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchSellerFollowerCountIfNeeded } from '@/redux/modules/sellerFollowerCount';
import { fetchSellerRatingSummaryByIdIfNeeded } from '@/redux/modules/sellerRatings';
import { fetchSellersDetailsIfNeeded } from '@/redux/modules/sellerDetail';
import { fetchTopSellersIfNeeded } from '@/redux/modules/topRatedSellers';
import { getCatalogSellerId } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getNotFoundUrl, toQueryString } from '@/utils/urls';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';

const reviewPageData = async ({ location, match, redirect, store }: DataLoaderParams) => {
    const { params } = match;
    const { dispatch } = store;
    const catalogId = params.catalogId;

    const userIsLoggedIn = isUserLoggedIn(store.getState());

    // Prompt user to login and return to this page
    if (!userIsLoggedIn) {
        return redirect(
            `/loginRedirect?${toQueryString({
                redirectTo: `${location.pathname}${location.search || ''}`,
            })}`
        );
    }

    await Promise.all([
        dispatch(fetchBidLimitBalanceIfNeeded({ catalogId })),
        dispatch(fetchCatalogDetailsIfNeeded([catalogId])),
        dispatch(fetchTopSellersIfNeeded()),
        dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] })),
    ]);

    const sellerId = getCatalogSellerId(store.getState(), catalogId);

    if (!sellerId) {
        return redirect(getNotFoundUrl());
    }

    await Promise.all([
        dispatch(fetchSellersDetailsIfNeeded([sellerId])),
        dispatch(fetchSellerFollowerCountIfNeeded([sellerId])),
        dispatch(fetchSellerRatingSummaryByIdIfNeeded(sellerId)),
    ]);
};

export default reviewPageData;
