import {
    getAccountCreatedDate,
    getAuthToken,
    getSignInProviderIsFacebook,
    getSignInProviderIsGoogle,
    isUserLoggedIn,
} from '@/redux/modules/account/user/user.selectors';
import { removeAuthCookie, setAuthCookie } from '@/redux/modules/cookies';
import { trackUserAuthenticated } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import getExpirationFromToken from '@/utils/getExpirationFromToken';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';

const Cookie = new Cookies();

const Auth = () => {
    const dispatch = useAppDispatch();
    const authToken = useAppSelector(getAuthToken);
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const prevAuthToken = usePrevious(authToken);
    const prevUserLoggedIn = usePrevious(userLoggedIn);
    const authCookie = Cookie.get('bidder-auth');
    const [queryParams] = useSearchParams();
    const socialLoginErrorParam = queryParams.get('error');

    const isGoogleAuth = useAppSelector(getSignInProviderIsGoogle);
    const isFacebookAuth = useAppSelector(getSignInProviderIsFacebook);
    const isSocialAuth = isGoogleAuth || isFacebookAuth;
    const accountCreatedDate = useAppSelector(getAccountCreatedDate);

    useEffect(() => {
        if (authToken && authToken !== prevAuthToken) {
            dispatch(trackUserAuthenticated());
            const expires = getExpirationFromToken(authToken);
            dispatch(setAuthCookie(expires, authToken));
        } else if (!userLoggedIn && authCookie) {
            dispatch(removeAuthCookie());
        }
    }, [authCookie, authToken, dispatch, prevAuthToken, prevUserLoggedIn, userLoggedIn]);

    // Handle social auth popup login messages. This will close the current page popup and send a message back to the main window
    // This is handled in the FacebookAuth and GoogleAuth components
    useEffect(() => {
        // Handle errors
        if (socialLoginErrorParam !== null && window.opener && authToken === '') {
            // Handle account already exists
            if (socialLoginErrorParam === 'social register already_exists') {
                window.opener.postMessage(
                    {
                        authToken,
                        message: 'social_register_already_exists',
                        success: false,
                        type: 'SOCIAL_AUTH',
                    },
                    window.location.origin
                );
                // Handle cancel clicked from facebook auth popup
            } else if (socialLoginErrorParam.includes('cannot fetch token')) {
                window.opener.postMessage(
                    {
                        authToken,
                        message: 'auth_cancelled',
                        success: false,
                        type: 'SOCIAL_AUTH',
                    },
                    window.location.origin
                );
            }
            // Handle success
        } else if (userLoggedIn && isSocialAuth && authToken && window.opener) {
            window.opener.postMessage(
                {
                    authToken,
                    message: 'success',
                    success: true,
                    type: 'SOCIAL_AUTH',
                },
                window.location.origin
            );
        }
    }, [userLoggedIn, dispatch, isGoogleAuth, accountCreatedDate, authToken, socialLoginErrorParam, isSocialAuth]);

    return null;
};

export default Auth;
