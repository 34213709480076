import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchRecommendedItemIfNeeded, getSortedRecommendedItemIdsFromState } from '@/redux/modules/recommendedItem';

export default ({ match, store }: DataLoaderParams) => {
    const { dispatch, getState } = store;
    const {
        params: { amazon },
    } = match;

    return dispatch(fetchRecommendedItemIfNeeded(Boolean(amazon))).then(() => {
        const state = getState();
        const itemIds = getSortedRecommendedItemIdsFromState(state, 0);
        return dispatch(
            fetchItemSummariesIfNeeded({
                identifier: 'RECOMMENDED-ITEMS-PAGE',
                itemIds,
            })
        );
    });
};
