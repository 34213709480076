import * as amplitude from '@amplitude/analytics-browser';
import { ApiHosts, buildUrl } from '@/redux/api/helpers';
import { EventContext } from '@/redux/modules/analytics';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getDeployment } from '@/redux/modules/config';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { ModalContext, openCompleteAccountSignUpModal, openLoginModal } from '@/redux/modules/modal';
import { postFetchBidderTokenByGoogleCredential } from '@/redux/modules/account/login/login.api';
import { submitLoginWithToken } from '@/redux/modules/account/login/login.actions';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useEffect, useRef } from 'react';
import { useKameleoon } from '@/hooks/kameleoon/useKameleoon';
import { useLocation } from 'react-router-dom';

export const GoogleAuthWidget = () => {
    const scriptLoaded = useRef(false);
    const deployment = useAppSelector(getDeployment);
    const dispatch = useAppDispatch();
    const { featureFlags, visitorCode } = useKameleoon();
    const location = useLocation();
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const pageName = getAmplitudePageNameFromPath(location.pathname);

    useEffect(() => {
        // Allow 3 before adding the prompt
        setTimeout(() => {
            // Handle script loading
            if (!scriptLoaded.current && !userLoggedIn && !window.google?.accounts?.id) {
                scriptLoaded.current = true;
                const script = document.createElement('script');
                script.src = 'https://accounts.google.com/gsi/client';
                script.defer = true;
                script.async = true;
                script.onload = () => {
                    if (window.google) {
                        const loginUri = buildUrl(ApiHosts.FlynnUser, deployment, 'auth/google/handle');

                        window.google.accounts.id.initialize({
                            callback: async (response) => {
                                if (response.credential) {
                                    try {
                                        const result = await postFetchBidderTokenByGoogleCredential({
                                            deployment,
                                            deviceId: amplitude.getDeviceId()?.toString(),
                                            googleCredential: response.credential,
                                            pageName,
                                            sessionId: amplitude.getSessionId()?.toString(),
                                            url: window.location.href,
                                            visitorCode,
                                        });

                                        const authToken = Object.values(result.payload)[0];

                                        await dispatch(
                                            submitLoginWithToken({
                                                authToken,
                                                featureFlags,
                                                location,
                                                shouldCloseModal: false,
                                            })
                                        );

                                        await dispatch(
                                            openCompleteAccountSignUpModal({
                                                eventContext: EventContext.join,
                                                type: 'join',
                                            })
                                        );
                                    } catch (error) {
                                        console.error('error', error);
                                        const modalContext: ModalContext = {
                                            eventContext: EventContext.social_register_already_exists,
                                            type: 'login',
                                        };
                                        dispatch(openLoginModal(modalContext));
                                    }
                                }
                            },
                            client_id: '956626885156-lull69ko2sefilu4j24ndqso9ghm3h3t.apps.googleusercontent.com',
                            context: 'signin',
                            itp_support: true,
                            login_uri: loginUri,
                            ux_mode: 'redirect',
                        });

                        window.google.accounts.id.prompt();
                    }
                };

                document.head.appendChild(script);
            }
        }, 3000);
    }, [deployment, dispatch, featureFlags, location, pageName, userLoggedIn, visitorCode]);

    return null;
};
