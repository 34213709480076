import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchAllSellersIfNeeded } from '@/redux/modules/seller';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';

export default async ({ store }: DataLoaderParams) => {
    const { dispatch } = store;
    return Promise.all([
        dispatch(fetchAllSellersIfNeeded()),
        dispatch(
            fetchCatalogAdIfNeeded({
                pageName: 'leaderboard-auctions-directory',
            })
        ),
    ]);
};
