import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchFeaturedCatalogs, getFeaturedCatalogs } from '@/redux/modules/featuredCatalogs';

export default ({ store }: DataLoaderParams) => {
    const { dispatch, getState } = store;

    return dispatch(fetchFeaturedCatalogs()).then(() => {
        const state = getState();
        const catalogIds = getFeaturedCatalogs(state);
        return Promise.all([dispatch(fetchCatalogsIfNeeded({ catalogIds }))]);
    });
};
