import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchCatalogRegistrationsForUserByIdIfNeeded } from '@/redux/modules/catalog/registration/catalogRegistration.actions';
import { fetchItemFacetsIfNeeded } from '@/redux/modules/itemFacets';
import { getBidPlacedCatalogIds } from '@/redux/modules/item/userBidPlacedItems/userBidPlacedItems.selectors';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { loadUserPlacedBidItemSummaries } from '@/redux/modules/item/userBidPlacedItems/userBidPlacedItems.actions';
import { parseQueryParams } from '@/utils/queryParams';
import { toQueryString } from '@/utils/urls';
import validateMyBidsPageParams, { MyBidsPageParams } from './validateMyBidsPageParams';
import type { FetchUserPlacedBidItemSummariesResponse } from '@/redux/modules/item/userBidPlacedItems/userBidPlacedItems.types';

const myBidsPageData = async ({ location, redirect, store }: DataLoaderParams) => {
    const params = parseQueryParams<MyBidsPageParams>(location.search);
    const { dispatch, getState } = store;

    const userIsLoggedIn = isUserLoggedIn(getState());

    // Prompt user to log in and return to this page
    if (!userIsLoggedIn) {
        return redirect(
            `/loginRedirect?${toQueryString({
                redirectTo: `${location.pathname}${location.search || ''}`,
            })}`
        );
    }

    // get items for page
    const { payload } = await dispatch(loadUserPlacedBidItemSummaries(validateMyBidsPageParams(params)));
    const bidPlacedCatalogIds = getBidPlacedCatalogIds(getState());

    const itemIds = ((payload as FetchUserPlacedBidItemSummariesResponse['payload']).items ?? []).map(
        ({ itemId }) => itemId
    );

    await Promise.all([
        // get catalog registrations for items
        dispatch(
            fetchCatalogRegistrationsForUserByIdIfNeeded({
                catalogIds: bidPlacedCatalogIds,
            })
        ),
        // get the ad for page
        dispatch(fetchCatalogAdIfNeeded({ pageName: 'leaderboard-my-bids' })),
        dispatch(fetchItemFacetsIfNeeded(itemIds)),
    ]);
};

export default myBidsPageData;
