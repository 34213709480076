import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogDetailsIfNeeded } from '@/redux/modules/catalog/detail/catalogDetail.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchStreamIdIfNeeded } from '@/redux/modules/video';
import { getNotFoundUrl } from '@/utils/urls';

const AuctionVideoPageData = async ({ location, redirect, store }: DataLoaderParams) => {
    const { pathname } = location;
    const { dispatch } = store;

    // If we dont have the catalogId here then we're going to abort
    const catalogId = Number(pathname.split('/').pop()) || 0;
    if (!catalogId) {
        return redirect(getNotFoundUrl());
    }

    return await Promise.all([
        dispatch(fetchCatalogDetailsIfNeeded([catalogId])),
        dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] })),
        dispatch(fetchStreamIdIfNeeded(catalogId)),
    ]);
};

export default AuctionVideoPageData;
