import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCategoryRelatedSearches } from '@/redux/modules/categoryRelatedSearches';
import { fetchItemCarouselsIfNeeded } from '@/redux/modules/itemCarousel/itemCarousel.actions';
import { fetchItemFacetsIfNeeded } from '@/redux/modules/itemFacets';
import { fetchItemSummaries } from '@/redux/modules/item/summary/itemSummary.actions';
import { getItemIdFromPath } from '@/utils/urls';
import { getPriceResults, getPriceResultsData } from '@/redux/modules/priceResults/priceResults.actions';

export default async ({ match, store }: DataLoaderParams) => {
    const { params } = match;
    const { dispatch } = store;
    const slug = params.slugId;
    const slugItemId = getItemIdFromPath(`/item/${slug}`);
    // prefetch price result data based on slug
    // only and obtain preferred slugItemId to fetch relevant item info
    const priceResultData = await Promise.all([
        dispatch(getPriceResultsData(slug)),
        dispatch(getPriceResults({ slug })),
    ]);

    const itemId = priceResultData[0]?.payload?.lotId ?? (slugItemId || 0);
    const salesHistoryItemsItemIds = priceResultData[1]?.payload?.salesHistoryItems?.map((item) => item.itemId) || [];

    const promises = [];
    if (itemId) {
        promises.push(dispatch(fetchItemFacetsIfNeeded([itemId])));
        promises.push(
            dispatch(
                fetchItemSummaries({
                    force: true,
                    identifier: 'price-results-page',
                    itemIds: [itemId],
                    similarItems: true,
                })
            )
        );
        promises.push(dispatch(fetchItemCarouselsIfNeeded(itemId)));
    }
    promises.push(dispatch(fetchCategoryRelatedSearches([...salesHistoryItemsItemIds])));
    await Promise.all(promises);

    return Promise.resolve();
};
