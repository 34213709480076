import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCertificatesIfNeeded } from '@/redux/modules/certCapture';
import { fetchEmailListsIfNeeded } from '@/redux/modules/emailList';
import { fetchTwoStepAuthenticationActivationStatus } from '@/redux/modules/account/twoStepAuthentication/twoStepAuthentication.actions';
import { getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getIsReseller } from '@/redux/modules/reseller';
import { toQueryString } from '@/utils/urls';

export const accountSettingsPageData = async ({ location, redirect, store }: DataLoaderParams) => {
    const { dispatch, getState } = store;

    const bidderId = getBidderId(getState());
    const userIsLoggedIn = isUserLoggedIn(store.getState());
    const isReseller = getIsReseller(store.getState());

    // Prompt user to login and return to this page
    if (!userIsLoggedIn) {
        return redirect(
            `/loginRedirect?${toQueryString({
                redirectTo: `${location.pathname}${location.search || ''}`,
            })}`
        );
    }

    await Promise.all([
        dispatch(fetchTwoStepAuthenticationActivationStatus(bidderId)),
        dispatch(fetchEmailListsIfNeeded()),
        ...(isReseller ? [dispatch(fetchCertificatesIfNeeded(true))] : []),
    ]);
};
