import { AuctionStatus } from '@/types/LiveAuctionState';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchAllBidderBiddingInfoIfNeeded } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.actions';
import { fetchBidIncrementsIfNeeded } from '@/redux/modules/bidIncrements/bidIncrements.actions';
import { fetchBidLimitBalanceIfNeeded } from '@/redux/modules/bidLimit';
import { fetchCatalogDetailsIfNeeded } from '@/redux/modules/catalog/detail/catalogDetail.actions';
import { fetchCatalogItemSummaries } from '@/redux/modules/catalog/items/catalogItems.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchConsoleBrandingIfNeeded } from '@/redux/modules/consoleBranding';
import { fetchItemDetailsIfNeeded, getItemDetailAgentId } from '@/redux/modules/itemDetail';
import { fetchListingAgentsIfNeeded } from '@/redux/modules/listingAgent';
import { fetchLiveCatalogRegistrationByIdIfNeeded } from '@/redux/modules/catalog/registration/catalogRegistration.actions';
import { fetchLiveCatalogStatusIfNeeded } from '@/redux/modules/liveCatalogStatus';
import { fetchSavedItemCountsIfNeeded } from '@/redux/modules/savedItemCount';
import { fetchSellersDetailsIfNeeded } from '@/redux/modules/sellerDetail';
import { fetchStreamIdIfNeeded } from '@/redux/modules/video';
import { fetchTopSellersIfNeeded } from '@/redux/modules/topRatedSellers';
import { getCatalogSellerId, getCatalogStatus } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getLiveCatalogCurrentItemId } from '@/redux/modules/console';
import { getNotFoundUrl } from '@/utils/urls';
import { loadCatalogFavorites } from '@/redux/modules/favorites/favorites.actions';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import TEST_HOUSES from '@/utils/testHouses';
import type { FetchItemSummariesByCatalogIdQueryType } from '@/redux/modules/item/summary/itemSummary.api';

const BiddingConsolePageData = async ({ location, redirect, store }: DataLoaderParams) => {
    const { pathname } = location;
    const { dispatch, getState } = store;

    // If we dont have the catalogId here then we're going to abort
    const catalogId = Number(pathname.split('/').pop()) || 0;
    if (!catalogId) {
        return redirect(getNotFoundUrl());
    }

    // fetch catalog to get sellerId
    await dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] }));

    // fetch live catalog status
    const sellerId = getCatalogSellerId(getState(), catalogId);
    await dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, sellerId, true));

    // If catalog is live, grab data for console
    const catalogStatus = getCatalogStatus(getState(), catalogId);
    if (catalogStatus !== AuctionStatus.Done) {
        const query: FetchItemSummariesByCatalogIdQueryType = {
            pageSize: 'all',
            preview: TEST_HOUSES.includes(sellerId),
            sort: SearchSortAndDirection.LotNumberLowest,
        };

        // catalog/console meta/seller data dependencies
        const catalogData = [
            dispatch(fetchBidIncrementsIfNeeded(catalogId)),
            dispatch(fetchCatalogDetailsIfNeeded([catalogId])),
            dispatch(fetchCatalogItemSummaries({ catalogId, query })),
            dispatch(fetchConsoleBrandingIfNeeded(catalogId, sellerId)),
            dispatch(
                fetchLiveCatalogRegistrationByIdIfNeeded({
                    catalogId,
                    sellerId,
                })
            ),
            dispatch(fetchSellersDetailsIfNeeded([sellerId])),
            dispatch(fetchStreamIdIfNeeded(catalogId)),
            dispatch(fetchTopSellersIfNeeded()),
            dispatch(loadCatalogFavorites(catalogId)),
        ];

        // current item/user data dependencies
        const itemId = getLiveCatalogCurrentItemId(getState(), catalogId);
        const itemData = [
            dispatch(fetchBidLimitBalanceIfNeeded({ catalogId, force: true, itemId })),
            dispatch(fetchItemDetailsIfNeeded([itemId])),
            dispatch(fetchSavedItemCountsIfNeeded([itemId])),
            dispatch(fetchAllBidderBiddingInfoIfNeeded(false)),
        ];

        // fetch the catalog + current item data
        await Promise.all([...catalogData, ...itemData]);

        // if there is a listing agent, fetch its details
        const agentId = getItemDetailAgentId(getState(), catalogId);
        if (agentId) {
            await dispatch(fetchListingAgentsIfNeeded([agentId]));
        }
    }
};

export default BiddingConsolePageData;
