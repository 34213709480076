import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchItemSummaryIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { getNotFoundUrl } from '@/utils/urls';

const imageCarouselPopoutPageData = async ({ location, redirect, store }: DataLoaderParams) => {
    const { pathname } = location;
    const { dispatch } = store;

    // If we dont have the itemId here then we're going to abort

    const itemId = Number(pathname.split('/')[3]) || 0;
    if (!itemId) {
        return redirect(getNotFoundUrl());
    }

    return await Promise.all([
        dispatch(
            fetchItemSummaryIfNeeded({
                identifier: 'image-carousel-popout',
                itemId,
            })
        ),
    ]);
};

export default imageCarouselPopoutPageData;
