import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchSellersIfNeeded } from '@/redux/modules/seller';

export default ({ match, store }: DataLoaderParams) => {
    const { params } = match;
    const { dispatch } = store;
    const sellerId = Number(params?.sellerId);

    if (sellerId) {
        return dispatch(fetchSellersIfNeeded([sellerId]));
    }
    return Promise.resolve();
};
