import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchBidderStatusesByIdIfNeeded, fetchHelpfulCountsByIdIfNeeded } from '@/redux/modules/reviewStatus';
import {
    fetchSellerCommentsByIdIfNeeded,
    fetchSellerPercentilesByIdIfNeeded,
    fetchSellerRatingsByIdIfNeeded,
    getSellerRatingById,
} from '@/redux/modules/sellerRatings';
import { fetchSellersIfNeeded } from '@/redux/modules/seller';
import { parseQueryParams } from '@/utils/queryParams';

export default ({ location, match, store }: DataLoaderParams) => {
    const { params } = match;
    const id = params.sellerId;
    const { dispatch, getState } = store;
    const paramsFromLocation: any = parseQueryParams(location.search);
    const page = paramsFromLocation.page || 1;
    const pageSize = paramsFromLocation.pageSize || 50;
    const filter = paramsFromLocation.filter || '';
    const sort = paramsFromLocation.sort || '';
    const promises = [
        dispatch(fetchSellersIfNeeded([id])),
        dispatch(
            fetchSellerRatingsByIdIfNeeded({
                filter,
                page,
                pageSize,
                sellerId: id,
                sort,
            })
        ),
        dispatch(fetchSellerPercentilesByIdIfNeeded(id)),
        dispatch(fetchSellerCommentsByIdIfNeeded(id)),
    ];
    return Promise.all(promises).then(() => {
        const state = getState();
        const rating = getSellerRatingById(state, id);
        if (Boolean(rating?.reviews)) {
            const ids = rating.reviews.map((r) => r.reviewId);
            return Promise.all([
                dispatch(fetchBidderStatusesByIdIfNeeded(ids)),
                dispatch(fetchHelpfulCountsByIdIfNeeded(ids)),
            ]);
        }
    });
};
