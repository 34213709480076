import { changeStorefrontPage, changeStoreFrontSearchTerms, getStorefrontViewData } from '@/redux/modules/viewData';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import { getNotFoundUrl } from '@/utils/urls';
import { itemSortOrders } from '@/enums';
import { loadStorefrontItems } from '@/redux/modules/storefront';
import { parseQueryParams, validateQueryParams } from '@/utils/queryParams';
import { recordAnalyticsForStorefrontPageView } from '@/redux/modules/analytics';

export default ({ location, match, redirect, store }: DataLoaderParams) => {
    const { params } = match;
    const queryParams = parseQueryParams(location.search);
    const { page, terms } = validateQueryParams(queryParams, itemSortOrders);

    const { dispatch, getState } = store;
    const state = getState();

    const sellerId = Number(params.sellerId);

    if (sellerId < 1 || isNaN(sellerId)) {
        return redirect(getNotFoundUrl());
    }

    const viewData = getStorefrontViewData(state, sellerId);

    const promises: Promise<any>[] = [dispatch(loadStorefrontItems(sellerId))];

    if (viewData.page !== page) {
        promises.push(dispatch(changeStorefrontPage(sellerId, page)));
    }

    if (viewData.searchTerms !== terms) {
        promises.push(dispatch(changeStoreFrontSearchTerms(sellerId, terms)));
    }

    return Promise.all(promises).then(() => {
        return dispatch(
            recordAnalyticsForStorefrontPageView({
                houseId: sellerId,
                location,
            })
        );
    });
};
