import * as React from 'react';
import {
    getAuthCookie,
    getBidderHasLoggedInCookie,
    getJoinModalLastSeenCookie,
    getPaginationPageSizeCookie,
} from '@/redux/modules/cookies';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getClientHydrated, hydrateClientState } from '@/redux/modules/client';
import { getPageSizeFromCookie } from '@/utils/pagination';
import { parseQueryParams } from '@/utils/queryParams';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import useIsomorphicLayoutEffect from '@liveauctioneers/caterwaul-components/lib/hooks/useIsomorphicLayoutEffect';

const Hydrate = () => {
    const { pathname, search } = useLocation();
    const dispatch = useAppDispatch();
    const isClientHydrated = useAppSelector(getClientHydrated);
    const authToken = useAppSelector(getAuthToken);
    const { hideForTesting }: any = parseQueryParams(search);
    const isBiddingConsole = pathname.includes('/console/');

    const token = useAppSelector(getAuthCookie);
    const hasLoggedInBefore = useAppSelector(getBidderHasLoggedInCookie) || Boolean(token);
    const dateLastShown = useAppSelector(getJoinModalLastSeenCookie);
    const pageSizeCookie = useAppSelector(getPaginationPageSizeCookie);

    const hydrateClient = React.useCallback(() => {
        const pageSize = getPageSizeFromCookie(pageSizeCookie);
        const disableModalForTesting = isBiddingConsole || hideForTesting === 'true';

        dispatch(
            hydrateClientState({
                dateLastShown,
                disableModalForTesting,
                hasLoggedInBefore,
                pageSize,
            })
        );
    }, [dateLastShown, dispatch, hasLoggedInBefore, hideForTesting, isBiddingConsole, pageSizeCookie]);

    useIsomorphicLayoutEffect(() => {
        if (!isClientHydrated) {
            hydrateClient();
        }

        return () => {
            window.onstorage = null;
        };
    }, [authToken, dispatch, hydrateClient, isClientHydrated]);

    return null;
};

export default Hydrate;
