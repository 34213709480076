import { AlgoliaMapBounds } from '../types/AuctionsNearMe.types';

export const defaultHitsPerPage = 24;

export const defaultAuctionsNearMeUsaBounds: AlgoliaMapBounds = {
    northEast: {
        lat: 46.4,
        lng: -64.3,
    },
    southWest: {
        lat: 28.7,
        lng: -123.4,
    },
};

export const defaultAuctionsNearMeUkBounds: AlgoliaMapBounds = {
    northEast: {
        lat: 59.147002,
        lng: 3.772578,
    },
    southWest: {
        lat: 49.686385,
        lng: -10.727177,
    },
};
