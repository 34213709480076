import { ShippingPolicyTranslation } from '@/intl/partnerShippingPolicies/shippingPolicy.types';

export const packengers: ShippingPolicyTranslation = {
    policy:
        '<h2>TERMS AND CONDITIONS OF SERVICE</h2>{newLine}' +
        'These terms and conditions of service constitute a legally binding contract between THEPACKENGERS, INC. (referred to as the <strong>"Company"</strong>) and the <strong>"Customer"</strong>. The following Terms and Conditions are deemed a part of all pick-up and/or delivery documents used by the Company in connection with the performance of all services for a Customer.{newLine}' +
        '<strong>1. Definitions</strong>{newLine}' +
        '<ListItem>(a) "Company" means <strong>THEPACKENGERS, INC.</strong></ListItem>{newLine}' +
        '<ListItem>(b) "Customer" means the person for which the Company is rendering service. It is the responsibility of the Customer to provide notice and copy(s) of these terms and conditions of service to all such agents or representatives.</ListItem>{newLine}' +
        '<ListItem>(c) "Documentation" means information received directly or indirectly from Customer in paper or electronic form.</ListItem>{newLine}' +
        '<ListItem>(d) Ocean Transportation Intermediaries" (<strong>"OTI"</strong>) shall include an "ocean freight forwarder" and a "non-vessel operating common carrier".</ListItem>{newLine}' +
        '<ListItem>(e) "Third parties" shall include, but not be limited to, the following: "carriers, truckmen, cartmen, lightermen, forwarders, OTI’s customs brokers, agents, warehousemen and others to whom the goods are entrusted for transportation, cartage, handling and/or delivery and/or storage or otherwise".</ListItem>{newLine}' +
        '<ListItem>(f) United States Customs and Border Protection (<strong>“Customs”</strong>) shall mean an agency of the United States of America.</ListItem>{newLine}' +
        '<ListItem>(g) Government “Agencies” shall mean all U.S. Governmental units other than Customs.</ListItem>{newLine}' +
        '<strong>2. Company As Agent.</strong>{newLine}' +
        'The Company only acts as Customer’s "agent" for the purpose of performing duties in connection with the release of goods, the securing of export licenses, the filing of export documentation on behalf of Customer and other dealings with Customs and Government Agencies; as to all other services, the Company acts as an independent contractor and not as Customer’s agent.{newLine}' +
        '<strong>3. Obligations of Customer and Limitation of Actions.</strong>{newLine}' +
        '<ListItem>(a) Unless subject to a statute or applicable law, all claims against the Company for a potential or actual loss, must be made in writing and received by the Company, within Three (3) business days of the event giving rise to claim; the failure to give the Company timely notice shall be a complete defense to any suit or action commenced by Customer or any other party claiming any rights through Customer including but not limited to Customer’s insurance carrier.</ListItem>{newLine}' +
        '<ListItem>(b) Every customer is expected to open the package and immediately examine the item shipped. It is the sole responsibility of Customer and/or its agents to properly inspect all goods shipped, handled or delivered by the Company immediately upon delivery by the Company to Customer or its agent. If any damage is found upon such inspection it must be duly noted on the delivery documentation of the Company at the time of delivery. The failure to include a description of such damage on such documentation shall be equivalent to a binding acknowledgement that all goods were delivered in good and satisfactory condition, free of any damage. Any subsequent claim of damage must be predicated upon the notation thereof on the Company’s delivery documentation. The absence of any such notation of damage shall be conclusive evidence that all goods were delivered to Customer in good and satisfactory order and condition, free of any damage. Take photos of the damage. You must also file a Declaration of the damages with the Company within Three (3) business days of delivery. This declaration should also be sent by email to: {email}. You must include all of the following documents with your declaration:</ListItem>{newLine}' +
        '<SubListItem>(i) Copies of Invoice(s) of the sale and Delivery Slip with your statement of damages,</SubListItem>{newLine}' +
        '<SubListItem>(ii) Photographs of the damage, estimate of repair and/or loss.</SubListItem>{newLine}' +
        '<SubListItem>(iii) Other documents may be requested, depending on the nature of the incident.</SubListItem>{newLine}' +
        '<ListItem>(c) With respect to Customer goods that are delivered to us or which we pick-up at the direction of Customer for domestic delivery or export, unless directed otherwise by Customer or unless the condition of the packing or crate indicates the likelihood that the goods may have been damaged, we will not unpack or uncrate such goods and will receive such goods as “unchecked” for delivery or export in the packing or crate in which they were received. If the Customer instructs us to repack or crate the goods for delivery or export then, in such case, we shall unpack and inspect the goods immediately upon receipt at our warehouse or upon pick-up by our staff and any damage found will be duly noted at that time. The Company shall have no liability for any damage to goods that were received by us as “unchecked” or for damage noted by us at the time of delivery or pick-up.</ListItem>{newLine}' +
        '<ListItem>(d) All suits against Company must be filed and properly served on Company as follows:</ListItem>{newLine}' +
        '<SubListItem>(i) For claims arising out of ocean transportation, within one (1) year from the date of the loss;</SubListItem>{newLine}' +
        '<SubListItem>(ii) For those claims arising from air transportation, within two (2) years from the date of the loss;</SubListItem>{newLine}' +
        '<SubListItem>(iii)For any and all other claims of any other type, within one (1) years from the date of the loss or damage.</SubListItem>{newLine}' +
        '<ListItem>(e) All quotes for services assume “easy access” for the pick-up and delivery of Customers goods. All problems affecting such easy access must be reported to the Company and may result in additional charges.</ListItem>{newLine}' +
        '<strong>Do not attempt to repair or restore the goods in advance of receiving confirmation from THE FINAL CARRIER OR THE COMPANY or your claim will not be accepted.</strong>{newLine}' +
        '<strong>4. No Liability For Services of Third Parties and/or Routes.</strong> Unless services are performed by persons or firms engaged pursuant to express written instructions from the Customer, the Company shall use reasonable care in its selection of third parties, or in selecting the means, route and procedure to be followed in the handling, transportation, clearance and delivery of the shipment; the advice by the Company that a particular person or firm has been selected to render services with respect to the goods, shall not be construed as a warranty or representation that such person or firm will render such services nor does the Company assume responsibility or liability for any action(s) and/or inaction(s) of such third parties and/or its agents. The Company shall not be liable for any delay or loss of any kind, which occurs while a shipment is in the custody or control of a third party or the agent of a third party; all claims in connection with an act of a third party shall be brought solely against such party and/or its agents and not the Company; in connection with any such claim, the Company shall reasonably cooperate with the Customer, which shall be liable for any charges or costs incurred by the Company in connection with such cooperation.{newLine}' +
        '<strong>5. Duty to Furnish Information.</strong>{newLine}' +
        '<ListItem>(a) For an export transaction, at a reasonable time prior to the exportation of the shipment, the Customer shall furnish to the Company the commercial invoice in proper form and number, a proper consular declaration, exact weights, measures, values and other information in the language of and as may be required by the laws and regulations of the U.S. and of the country of destination of the goods. If the Customer fails to furnish such information in a timely manner in whole or in part, as may be required to complete the all export requirements of U.S. Customs and entry requirements of the country of destination, or if the information furnished is inaccurate or incomplete, the Company shall have no obligation whatsoever for any damages due to delays in shipment of the goods or for the cost of correcting the improper documentation provided by Customer and the Company shall be obligated only to use its best judgment in connection with the shipment and in no instance shall be charged with knowledge by the Customer of the true circumstances to which such inaccurate, incomplete, or omitted information or document pertains.</ListItem>{newLine}' +
        '<ListItem>(b) For export transactions the Company shall not be responsible or liable for increased duty, penalty, fine or expense unless caused by the negligence or other fault of the Company, in which event its liability to the Customer shall be governed by the provisions set forth herein. The Customer shall be bound by and warrant the accuracy of all invoices, documents and information furnished to the Company by the Customer or its agent for export, entry or other purposes and Customer agrees to indemnify and hold harmless the Company against increased duty, penalty, fine or expense including attorneys’ fees, resulting from any inaccuracy, incomplete statement, omission or failure to make timely presentation, even if not due to Customer’s negligence.</ListItem>{newLine}' +
        '<ListItem>(c) Customer acknowledges that Customer is required to review all documents and declarations prepared and/or filed with the Customs Service, other Government Agency and/or third parties, and will immediately advise the Company of any errors, discrepancies, incorrect statements, or omissions on any declaration filed on Customer’s behalf.</ListItem>{newLine}' +
        '<ListItem>(d) In preparing and submitting Customs export declarations, applications, documentation and/or export data to a third party, the Company relies on the correctness of all documentation, whether in written or electronic format, and all information furnished by Customer; Customer shall use reasonable care to ensure the correctness of all such information and shall indemnify and hold the Company harmless from any and all claims asserted and/or liability or losses suffered by reason of any incorrect or false statement upon which the Company reasonably relied.</ListItem>{newLine}' +
        '<strong>6. Declaring Higher Value to Third Parties.</strong> Third parties to whom the goods are entrusted may limit liability for loss or damage; the Company will request excess valuation coverage only upon specific written instructions from the Customer, which must agree to pay any charges therefor; in the absence of written instructions from the Customer the Company shall be under no obligation to request excess valuation coverage.{newLine}' +
        '<strong>7. Insurance.</strong> As part of its quote for services, the Company includes basic insurance coverage for Customer’s shipment. This insurance is provided by the Company automatically unless Customer notifies the Company in writing at the time of placing its order for services that it elects to not have the Company insure its shipment in which event the Company shall bear no responsibility for damage or loss to Customer’s shipment except in the case of the willful or grossly negligent acts of the Company or its employees and agents. The Company shall bear no responsibility for damage or loss to a frame or frames altered in transit. The waiver of insurance coverage by Customer will reduce the charge for the Company’s services by the amount of the premium charged to the Company for such coverage.{newLine}' +
        '<strong>8. Disclaimers; Limitation of Liability.</strong>{newLine}' +
        '<ListItem>(a) Except as specifically set forth herein, Company makes no express or implied warranties regarding its services.</ListItem>{newLine}' +
        '<ListItem>(b) Subject to (d), Customer agrees that with respect to any services performed by the Company, the Company shall only be liable for its negligent acts, if they are the direct and proximate cause of any injury to Customer, including loss or damage to Customer’s goods, and the Company shall in no event be liable for the acts of third parties.</ListItem>{newLine}' +
        '<ListItem>(c) In connection with all services performed by the Company, Customer may, as set forth in Paragraph 7 above, obtain additional liability insurance coverage, up to the actual or declared value of the shipment or transaction, by requesting such coverage and agreeing to make payment therefor, which request must be confirmed in writing by the Company prior to rendering services for the covered transaction(s);</ListItem>{newLine}' +
        "<ListItem>(d) In the absence of additional coverage under (c) above, the Company's liability shall be limited to the following:</ListItem>{newLine}" +
        '<SubListItem>(i) where the claim arises from activities other than those relating to customs brokerage, $50.00 per shipment or transaction or 60 ($0.60) Cents per pound of weight of the lost or damaged article, whichever is greater, unless a different value per article is declared and the Company agrees to such rates in writing prior to performance of the services; or</SubListItem>{newLine}' +
        '<SubListItem>(ii) where the claim arises from activities relating to "Customs business," $50.00 per entry or the amount of brokerage fees paid to Company for the entry, whichever entry, whichever is less.</SubListItem>{newLine}' +
        '<ListItem>(e) In no event shall the Company be liable or responsible for consequential, indirect, incidental, statutory or punitive damages even if it has been put on notice of the possibility of such damages, except where such damages or loss have been caused by the willful or malicious acts of the Company or any of its employees or agents.</ListItem>{newLine}' +
        '<ListItem>(f) As set forth above, it shall be Customer’s obligation to inspect, or have its agent inspect, all goods delivered by the Company at the time of delivery and to note on the Company’s delivery documentation any damages to the delivered goods. Absent such notation of damage to Customer’s goods, the Company shall have no liability with respect to any claim of damage to Customer’s goods.</ListItem>{newLine}' +
        '<strong>9. No Responsibility for Governmental Requirements.</strong> It is the responsibility of the Customer to know and comply with the marking requirements of the United States, the regulations of the U.S. Food and Drug Administration, and all other requirements, including regulations of Federal, state and/or local agencies pertaining to the merchandise. The Company shall not be responsible for action taken or fines or penalties assessed by any governmental agency against the shipment because of the failure of the Customer to comply with the law or the requirements or regulations of any governmental agency or with a notification issued to the Customer by any such agency.{newLine}' +
        '<strong>10. Right to Store.</strong> If for any reason other than the fault of the Company, delivery cannot be made at address requested by customer, or at any changed address of which the Company has been notified, the Company, at its option, may cause articles contain in shipment to be stored in a warehouse selected by it at the point of delivery or at other available points, at the cost of the owner, and subject to a lien for all accrued tariff and other lawful charges.{newLine}' +
        '<strong>11. Damage to Premises.</strong> Company shall not be liable for any damages to the premises from which we retrieve Customer’s property or to which such property is delivered by us, unless caused by the gross negligence of the Company or our employees. In the event that such damage is caused by our sole negligence, our liability shall be limited to a maximum of $50.00 in the aggregate for all such damage.{newLine}' +
        '<strong>12. No Modification or Amendment Unless Written.</strong> These terms and conditions of service may only be modified, altered or amended in writing signed by both Customer and Company; any attempt to unilaterally modify, alter or amend the same shall be null and void.{newLine}' +
        '<strong>13. Severability.</strong> In the event any Paragraph(s) and/or portion(s) hereof are found to be invalid and/or unenforceable, then in such event the remainder hereof shall remain in full force and effect.{newLine}' +
        '<strong>14. Governing Law; Consent to Jurisdiction and Venue.</strong> These terms and conditions of service and the relationship of the parties shall be construed according to the laws of the State of New York, without giving consideration to principles of conflict of law. Customer and Company (a) irrevocably consent to the jurisdiction of the State courts of New York; (b) agree that any action relating to the services performed by Company, shall only be brought in said courts; (c) consent to the exercise of in personam jurisdiction by said courts over it, and (d) further agree that any action to enforce a judgment may be instituted in any jurisdiction.',
};
