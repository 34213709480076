import { buildSearchQuery } from '@/utils/search/searchQuery';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import { DEFAULT_SORT_ORDER } from '@/redux/modules/search/search.types';
import { fetchAllCategoriesLandingPageData, fetchCategoriesIfNeeded } from '@/redux/modules/category/category.actions';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchCatalogIds, getCatalogIdsForCategory } from '@/redux/modules/categoryCatalogCards';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchCategoryRelatedSearches } from '@/redux/modules/categoryRelatedSearches';
import {
    fetchCombinationCategory,
    fetchCombinationRelatedSearches,
    fetchCombinationSuggestedCategories,
} from '@/redux/modules/combinationCategory';
import { fetchItemFacetsIfNeeded, getItemFacets } from '@/redux/modules/itemFacets';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchSearchFacetsIfNeeded } from '@/redux/modules/searchFacets';
import { fetchSimilarItemsIfNeeded, getSimilarItemIds } from '@/redux/modules/similarItems';
import { fetchSubcategories } from '@/redux/modules/subcategoryTiles';
import { fetchSuggestedCategories } from '@/redux/modules/suggestedCategoryTiles';
import { getCategoryEntries } from '@/redux/modules/category/category.selectors';
import {
    getCategoryIdsFromPathname,
    getCategoryNameFromPathname,
    getCombinationCategoryIdsFromPath,
    getFinalCategoryIdFromPathname,
    isSimilarItemsPage,
    urlSafeTitle,
} from '@/utils/urls';
import { getFacetIds } from '@/utils/ItemPageUtils';
import { getFilterTermFromQueryParams, isArchivedSearch, parseQueryParams } from '@/utils/queryParams';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import {
    getRawArchivedSearchFacets,
    getRawUpcomingSearchFacets,
} from '@/redux/modules/searchFacetAggregates.selectors';
import { getSearchedItemIds, getSearchUiState } from '@/redux/modules/search/search.selectors';
import { getSearchPageSize } from '@/redux/modules/pagination';
import { searchCategory } from '@/redux/modules/landingPage';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

const DEFAULT_PRICE_GUIDE_SORT = SearchSortAndDirection.TimeNewest;

export default ({
    location,
    match,
    redirect,
    store,
}: DataLoaderParams<{
    categoryId: string;
    categoryId2: string;
    deleted?: number;
}>) => {
    const { params } = match;
    const { dispatch, getState } = store;
    const queryParams = parseQueryParams(location.search);
    const { pathname } = location;
    // if an itemId is passed, use for /similar-items/ result formatting
    const itemId = Number(queryParams?.itemId);
    const archivedSearch = isArchivedSearch(queryParams, pathname);
    const pageName = archivedSearch ? 'price-guide' : 'category';
    const keywords = getFilterTermFromQueryParams(queryParams);
    const promises: Promise<any>[] = [
        dispatch(fetchSearchFacetsIfNeeded(archivedSearch)),
        dispatch(
            fetchCatalogAdIfNeeded({
                categoryIds: getCategoryIdsFromPathname(pathname),
                keywords: `${keywords}`,
                pageName,
            })
        ),
        dispatch(fetchSubcategories(getFinalCategoryIdFromPathname(pathname))),
        dispatch(fetchCategoryRelatedSearches([itemId], getFinalCategoryIdFromPathname(pathname))),
        dispatch(fetchSuggestedCategories(getFinalCategoryIdFromPathname(pathname))),
        dispatch(fetchCatalogIds(getCategoryNameFromPathname(pathname))),
    ];

    if (itemId && isSimilarItemsPage(pathname)) {
        promises.push(
            dispatch(
                fetchItemSummariesIfNeeded({
                    identifier: 'SIMILAR-ITEMS-CATEGORY-SEARCH-RESULTS',
                    itemIds: [itemId],
                })
            )
        );
    }

    // on top level category show catalog banner ad
    if (/\/c\/[\w-]+\/\d+/.test(pathname)) {
        promises.push(
            dispatch(
                fetchCatalogAdIfNeeded({
                    categoryIds: getCategoryIdsFromPathname(pathname),
                    force: true,
                    keywords: `${keywords}`,
                    pageName: 'category-banner',
                })
            )
        );
    }

    let combinationIds;
    if (params?.categoryId2) {
        // combinationIds encompass both the canonical url ids (categoryId1 and categoryId2) AND any ids from
        // the query params, eg category_id=123,234
        combinationIds = getCombinationCategoryIdsFromPath(params, queryParams);
        if (Boolean(combinationIds)) {
            promises.push(dispatch(fetchCombinationRelatedSearches(combinationIds)));
            promises.push(dispatch(fetchCombinationSuggestedCategories(combinationIds)));
        }
    }

    if (pathname === '/c/') {
        promises.push(dispatch(fetchAllCategoriesLandingPageData()));
    }

    return Promise.all(promises).then(() => {
        const currentState = getState();
        const defaultSortOrder = archivedSearch ? DEFAULT_PRICE_GUIDE_SORT : DEFAULT_SORT_ORDER;
        const pageSize = getSearchPageSize(currentState);
        const catalogIds = getCatalogIdsForCategory(currentState);

        // Experiments.PriceResultSimilarItemsCTA, default variant
        const isSimilarItemsVariant = false;

        let rawFacets;
        if (archivedSearch) {
            rawFacets = getRawArchivedSearchFacets(currentState);
        } else {
            rawFacets = getRawUpcomingSearchFacets(currentState);
        }
        const searchQuery = buildSearchQuery({
            defaultSortOrder,
            pathname,
            queryParams,
            rawFacets,
            searchPageSize: pageSize,
            similarItems: isSimilarItemsVariant,
        });

        let landingPageIds: number[] = []; // add more id's here

        if (params?.categoryId2) {
            landingPageIds = [Number(params.categoryId), Number(params.categoryId2)]; // this is probably duplicated with 'getCombinationCategoryIdsFromPath'
        } else if (params.categoryId) {
            landingPageIds = [Number(params.categoryId)];
        }

        const promises = [
            dispatch(fetchCatalogsIfNeeded({ catalogIds })),
            dispatch(searchCategory(searchQuery, landingPageIds)),
            dispatch(fetchCategoriesIfNeeded({ categoryIds: landingPageIds })),
        ];

        return Promise.all(promises).then(() => {
            const searchState = getState();
            const itemIds = getSearchedItemIds(searchState);
            const promises = [];

            // should redirect if no results page
            if (itemIds.length === 0 && queryParams?.page > 0) {
                return redirect(location.pathname, true);
            }

            // redirect to single category if there are no results on combination category
            if (itemIds.length === 0 && combinationIds) {
                const category = Object.values(getCategoryEntries(searchState))
                    .filter((category) => category.facetId === 1)
                    .pop();

                // fail gracefully, meaning don't redirect if no category was found
                if (category !== undefined && category?.name && category?.categoryId) {
                    return redirect(`/c/${urlSafeTitle(category.name)}/${category.categoryId}`, true);
                }
            }

            // combination category page data is needed AFTER category page data,
            // because the combination meta values need to override the standard category values
            if (combinationIds) {
                promises.push(dispatch(fetchCombinationCategory(combinationIds)));
            }

            // make an array of requests to issue, dispatching analytics after they all complete
            // i have no idea why we care about `params.deleted` but apparently if it's
            // there we need to also fetch the item with its id
            if (itemIds?.length) {
                if (params?.deleted && !isNaN(params.deleted)) {
                    promises.push(
                        dispatch(
                            fetchItemSummariesIfNeeded({
                                identifier: 'MY-SAVED-SEARCH-PAGE-DELETED',
                                itemIds: [params.deleted],
                            })
                        )
                    );
                }
                return Promise.all(promises);
            } else {
                const { soldItemIds = [] } = getSearchUiState(searchState);
                promises.push(
                    dispatch(
                        fetchItemSummariesIfNeeded({
                            identifier: 'MY-SAVED-SEARCH-PAGE-SOLD',
                            itemIds: soldItemIds,
                        })
                    )
                );
                promises.push(dispatch(fetchItemFacetsIfNeeded([soldItemIds[0]])));

                return Promise.all(promises).then(() => {
                    const state = getState();
                    const { itemId, sellerId, title } = getItemSummary(state, soldItemIds[0]);
                    const facets = getItemFacets(state, itemId);
                    const categoryIds = getFacetIds(facets);
                    return Promise.all([
                        dispatch(
                            fetchSimilarItemsIfNeeded({
                                categoryIds,
                                itemId,
                                sellerId,
                                title,
                            })
                        ),
                    ]).then(() => {
                        const state = getState();
                        const similarItemIds = getSimilarItemIds(state, itemId);
                        return dispatch(
                            fetchItemSummariesIfNeeded({
                                identifier: 'MY-SAVED-SEARCH-PAGE-SIMILAR',
                                itemIds: similarItemIds,
                            })
                        );
                    });
                });
            }
        });
    });
};
