import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchBidLimitBalanceIfNeeded } from '@/redux/modules/bidLimit';
import { fetchCatalogDetailsIfNeeded } from '@/redux/modules/catalog/detail/catalogDetail.actions';
import { fetchCatalogItemSummaries } from '@/redux/modules/catalog/items/catalogItems.actions';
import { FetchItemSummariesByCatalogIdQueryType } from '@/redux/modules/item/summary/itemSummary.api';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchLiveCatalogRegistrationByIdIfNeeded } from '@/redux/modules/catalog/registration/catalogRegistration.actions';
import { fetchLiveCatalogStatusIfNeeded } from '@/redux/modules/liveCatalogStatus';
import { fetchSellerFollowerCountIfNeeded } from '@/redux/modules/sellerFollowerCount';
import { fetchSellerRatingSummaryByIdIfNeeded } from '@/redux/modules/sellerRatings';
import { fetchSellersDetailsIfNeeded } from '@/redux/modules/sellerDetail';
import { fetchTopSellersIfNeeded } from '@/redux/modules/topRatedSellers';
import { fetchWonItemsForBannerIfNeeded } from '@/redux/modules/wonItems/wonItems.actions';
import { getAreUrlsEqual, getCatalogUrl, getIdFromPath, getNotFoundUrl } from '@/utils/urls';
import {
    getCatalogCoverLots,
    getCatalogIsPreview,
    getCatalogIsTimedPlus,
    getCatalogSellerId,
    getCatalogTitle,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getPaginationPageSize } from '@/redux/modules/pagination';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { loadCatalogs } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { parseQueryParams } from '@/utils/queryParams';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import caseInsensitiveCompare from '@liveauctioneers/caterwaul-components/lib/utils/caseInsensitiveCompare';
import TEST_HOUSES from '@/utils/testHouses';

interface QueryParams {
    buyNow?: string;
    keyword?: string;
    page?: string;
    pageSize?: string;
    sort?: SearchSortAndDirection;
}

export default async ({ featureFlagVariants, location, redirect, store }: DataLoaderParams) => {
    const params: QueryParams = parseQueryParams(location.search);
    const { pathname, search } = location;
    const { dispatch } = store;
    const catalogId = getIdFromPath(pathname);

    if (!catalogId) {
        return redirect(getNotFoundUrl());
    }

    // Always load the catalog data when we load the page
    await dispatch(loadCatalogs([catalogId]));
    const state = store.getState();
    const title = getCatalogTitle(state, catalogId);

    // These redirects we're only going to do on the server so only when window is undefined.
    if (typeof window === 'undefined') {
        if (!title) {
            // if we weren't able to get a catalog from the api then we return a 302
            return redirect(getNotFoundUrl());
        }

        const catalogUrl = getCatalogUrl(catalogId, title);

        if (!getAreUrlsEqual(pathname, catalogUrl)) {
            // if it is a bad url, then return a permanent redirect with the good url
            if (search) {
                return Promise.resolve(redirect(`${catalogUrl}${search}`, true));
            }
            // if it is a bad url, then return a permanent redirect with the good url
            return Promise.resolve(redirect(catalogUrl, true));
        }
    }

    const preview = getCatalogIsPreview(state, catalogId);
    const coverLots = getCatalogCoverLots(state, catalogId);
    const sellerId = getCatalogSellerId(state, catalogId);
    const loggedIn = isUserLoggedIn(state);

    const globalPageSize = getPaginationPageSize(state);
    const page = Number(params.page || 1);
    let pageSize = globalPageSize || 24;
    if (caseInsensitiveCompare(params?.pageSize, 'all')) {
        pageSize = 'all';
    } else if (!isNaN(Number(params.pageSize))) {
        pageSize = Number(params.pageSize);
    }

    // handle NaN
    const keyword = params.keyword || '';
    const sort = params.sort || SearchSortAndDirection.LotNumberLowest;
    const buyNow = params.buyNow !== 'false' && typeof params.buyNow !== 'undefined';

    const query: FetchItemSummariesByCatalogIdQueryType = {
        buyNow,
        keyword,
        page,
        pageSize,
        sort,
    };
    if (preview || TEST_HOUSES.includes(sellerId)) {
        query.preview = true;
    }

    const itemIds = coverLots.map((cl) => cl.itemId);

    await Promise.all([
        dispatch(fetchBidLimitBalanceIfNeeded({ catalogId })),
        dispatch(fetchCatalogDetailsIfNeeded([catalogId])),
        dispatch(fetchTopSellersIfNeeded()),
        dispatch(
            fetchItemSummariesIfNeeded({
                identifier: 'catalog-cover-items-for-quickload',
                itemIds,
            })
        ),
    ]);

    if (!sellerId) {
        return redirect(getNotFoundUrl());
    }

    const promises: Promise<unknown>[] = [
        dispatch(fetchTopSellersIfNeeded()),
        dispatch(fetchSellersDetailsIfNeeded([sellerId])),
        dispatch(fetchSellerFollowerCountIfNeeded([sellerId])),
        dispatch(fetchSellerRatingSummaryByIdIfNeeded(sellerId)),
        dispatch(fetchCatalogItemSummaries({ catalogId, query })),
    ];

    if (loggedIn) {
        promises.push(
            dispatch(
                fetchLiveCatalogRegistrationByIdIfNeeded({
                    catalogId,
                    sellerId,
                })
            )
        );
        promises.push(dispatch(fetchWonItemsForBannerIfNeeded({ featureFlagVariants })));
    }

    const isTimedPlus = getCatalogIsTimedPlus(state, catalogId);
    if (isTimedPlus) {
        promises.push(dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, sellerId)));
    }

    return Promise.all(promises);
};
