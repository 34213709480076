import * as React from 'react';
import { getBaseUrl, getWWWBaseUrl } from '@/redux/modules/config';
import { getIsWhiteLabel, getWhiteLabelName, getWhiteLabelSellerId } from '@/redux/modules/whiteLabel';
import { getSellerUrl } from '@/utils/urls';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PageHeaderMeta from '@liveauctioneers/caterwaul-components/lib/PageHeaderMeta/PageHeaderMeta';
import useAddNotificationCountToTitle from '@/hooks/useAddNotificationCountToTitle';

const AppHeaderMeta = () => {
    const { pathname, search } = useLocation();
    const baseUrl = useAppSelector(getBaseUrl);
    const whiteLabel = useAppSelector(getIsWhiteLabel);
    const whiteLabelName = useAppSelector(getWhiteLabelName);
    const whiteLabelSellerId = useAppSelector(getWhiteLabelSellerId);
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const wwwBaseUrl = useAppSelector(getWWWBaseUrl);

    const { formatMessage } = useIntl();

    const {
        colors: { blue200 },
    } = useTheme();

    const indexOfQuestion = pathname.indexOf('?');
    const path = !pathname.includes('?') ? pathname : pathname.substring(0, indexOfQuestion);

    const currentLangCanonical = `${baseUrl}${
        whiteLabel && path === '/' ? `${getSellerUrl(whiteLabelSellerId, whiteLabelName)}` : path
    }`;
    const wwwBaseCanonical = `${wwwBaseUrl}${path}${search ? search : ''}`;
    const ukBaseCanonical = `${wwwBaseUrl}/en-gb${path}${search ? search : ''}`;

    const iOSAppUrl = 'https://itunes.apple.com/us/app/live-auctions/id321243082';

    const description = formatMessage({ id: 'get_in_on_the_auction' });
    const title = useAddNotificationCountToTitle(formatMessage({ id: 'default_title' }));

    return (
        <PageHeaderMeta
            canonical={currentLangCanonical}
            description={description}
            title={title}
        >
            {!pathname.includes('/console/') && userLoggedIn && (
                <meta
                    content="app-id=321243082"
                    name="apple-itunes-app"
                />
            )}
            <link
                href={wwwBaseCanonical}
                hrefLang="en"
                rel="alternate"
            />
            <link
                href={ukBaseCanonical}
                hrefLang="en-gb"
                rel="alternate"
            />
            <link
                href={wwwBaseCanonical}
                hrefLang="x-default"
                rel="alternate"
            />
            <link
                href="/public-assets/apple-touch-icon.png"
                rel="apple-touch-icon"
                sizes="180x180"
            />
            <link
                href="/public-assets/favicon-32x32.png"
                rel="icon"
                sizes="32x32"
                type="image/png"
            />
            <link
                href="/public-assets/favicon-16x16.png"
                rel="icon"
                sizes="16x16"
                type="image/png"
            />
            <link
                href="/public-assets/site.webmanifest"
                rel="manifest"
            />

            <meta
                content={blue200}
                name="msapplication-TileColor"
            />
            <meta
                content={blue200}
                name="theme-color"
            />

            <meta
                content="600"
                property="og:image:height"
            />
            <meta
                content="600"
                property="og:image:width"
            />
            <meta
                content="en_US"
                property="og:locale"
            />
            <meta
                content="LiveAuctioneers"
                property="og:site_name"
            />
            <meta
                content="website"
                property="og:type"
            />

            <meta
                content="US"
                name="twitter:app:country"
            />
            <meta
                content="com.liveauctioneers.and"
                name="twitter:app:id:googleplay"
            />
            <meta
                content="321243082"
                name="twitter:app:id:ipad"
            />
            <meta
                content="321243082"
                name="twitter:app:id:iphone"
            />
            <meta
                content="LiveAuctioneers"
                name="twitter:app:name:googleplay"
            />
            <meta
                content="LiveAuctioneers"
                name="twitter:app:name:ipad"
            />
            <meta
                content="LiveAuctioneers"
                name="twitter:app:name:iphone"
            />
            <meta
                content="https://play.google.com/store/apps/details?id=com.liveauctioneers.and"
                name="twitter:app:url:googleplay"
            />
            <meta
                content={iOSAppUrl}
                name="twitter:app:url:ipad"
            />
            <meta
                content={iOSAppUrl}
                name="twitter:app:url:iphone"
            />
            <meta
                content="summary_large_image"
                name="twitter:card"
            />
            <meta
                content="liveauctioneers.com"
                name="twitter:domain"
            />
            <meta
                content="@liveauctioneers"
                name="twitter:site"
            />
            {whiteLabel && (
                <meta
                    content="noindex, nofollow"
                    name="robots"
                />
            )}
        </PageHeaderMeta>
    );
};

export default React.memo(AppHeaderMeta);
