/* eslint-disable sort-keys/sort-keys-fix */
export const CategoryNamesAndIds = {
    Art: 1,
    Jewelry: 6,
    'Asian Antiques': 158,
    Furniture: 4,
    Collectibles: 2,
    Coins: 24,
    'Home & Décor': 5,
    Fashion: 3,
};
