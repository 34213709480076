import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchReviewData } from '@/redux/modules/review';
import { fetchWonItemsIfNeeded } from '@/redux/modules/wonItems/wonItems.actions';
import { getWonItems } from '@/redux/modules/wonItems/wonItems.selectors';
import { parseQueryParams } from '@/utils/queryParams';

export default async ({ location, store }: DataLoaderParams) => {
    const { dispatch } = store;

    await dispatch(fetchCatalogAdIfNeeded({ pageName: 'leaderboard-won-items' }));

    const { page = 1, pageSize = 10 } = parseQueryParams(location.search);
    await dispatch(fetchWonItemsIfNeeded({ page, pageSize }));

    const wonCatalogs = getWonItems(store.getState());
    const catalogIds = wonCatalogs.map((c) => c.catalogId);

    if (catalogIds.length) {
        const catalogsPromise = dispatch(
            fetchCatalogsIfNeeded({
                catalogIds,
            })
        );
        await Promise.allSettled([catalogsPromise, dispatch(fetchReviewData(catalogIds))]);
    }
};
