import ms from 'ms';
import TEST_HOUSES from '@/utils/testHouses';

export const getAlgoliaMapFilters = (): string => {
    const filters = [getTestHouseFilter(), getOnlyFutureAuctionsFilter(), getOnlyPublishedUpcomingAuctionsFilter()];
    return filters.join(' AND ');
};

export const getTestHouseFilter = (): string => {
    return `NOT House.ID:${TEST_HOUSES.join(' AND NOT House.ID:')}`;
};

export const getOnlyFutureAuctionsFilter = (): string => {
    const d = new Date();
    // set to midnight this morning
    d.setHours(0, 0, 0, 0);
    // add 7200 to match the backend
    const offset = ms('2h');
    const currentTime = d.getTime() + offset;
    let secondsSinceEpoch = Math.round(currentTime / 1000);
    return `SaleStart > ${secondsSinceEpoch}`;
};

export const getOnlyPublishedUpcomingAuctionsFilter = (): string => {
    const validStatuses = ['online', 'live'];
    return `(Status:${validStatuses.join(' OR Status:')})`;
};
