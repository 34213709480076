import * as React from 'react';
import { HammerFooter as Footer } from '@/components/Footer/HammerFooter';
import { HammerHeader as Header } from '@/components/Header/HammerHeader';
import AppHeaderMeta from './AppHeaderMeta';
import BackToTop from '@/components/BacktoTop/BackToTop';
import HealthCheckOverlay from '@/components/HealthCheck/HealthCheckOverlay';
import isPropValid from '@emotion/is-prop-valid';
import Modals from '@/components/Modals/Modals';
import SailthruInit from '@/components/Sailthru/SailthruInit';
import SegmentTracker from '@/components/Segment/SegmentTracker';
import styled, { IStyleSheetContext, StyleSheetManager } from 'styled-components';

type Props = {
    children: React.ReactNode;
    showHeaderAndFooter: boolean;
};

/**
 * When we migrate completely to hammer-ui inputs and typography, we should be able to delete this prop filtering
 *
 * This implements the default behavior from styled-components v5
 * @see https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
 */
const shouldForwardProp: IStyleSheetContext['shouldForwardProp'] = (propName, target) => {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
};

const AppJSX = ({ children, showHeaderAndFooter }: Props) => (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <StyledApp>
            <AppHeaderMeta />
            <SailthruInit />
            {showHeaderAndFooter && <Header />}
            <StyledAppContent id="main">{children}</StyledAppContent>
            {showHeaderAndFooter && <Footer />}
            <HealthCheckOverlay />
            <Modals />
            <SegmentTracker />
            <BackToTop />
        </StyledApp>
    </StyleSheetManager>
);

export default AppJSX;

const StyledApp = styled.div`
    height: 100%;
    width: 100%;
    position: relative;

    @media print {
        overflow: initial;
    }
`;

const StyledAppContent = styled.main`
    height: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.pageWidthNum + 1}px) {
        margin: 0 auto;
    }

    @media print {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        margin-top: 0 !important;
    }
`;
