import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchHomeDataIfNeeded } from '@/redux/modules/home/home.actions';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchRecommendedItemIfNeeded, getSortedRecommendedItemIdsFromState } from '@/redux/modules/recommendedItem';
import {
    fetchSavedSearchesIfNeeded,
    fetchSavedSearchItemsIfNeeded,
    getSavedSearchItemIds,
    hasSavedSearchesSelector,
} from '@/redux/modules/savedSearch';
import { fetchSellerUpcomingCatalogsIfNeeded } from '@/redux/modules/sellerUpcomingCatalogs';
import { fetchUpcomingItemsIfNeeded } from '@/redux/modules/upcoming/items/upcomingItems.actions';
import { fetchWonItemsForBannerIfNeeded } from '@/redux/modules/wonItems/wonItems.actions';
import { getFollowedSellerIds } from '@/redux/modules/followSeller';
import { getUpcomingItemIds } from '@/redux/modules/upcoming/items/upcomingItems.selectors';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';

export default ({ featureFlagVariants, store }: DataLoaderParams) => {
    const { dispatch, getState } = store;
    const state = getState();
    const hasSavedSearches = hasSavedSearchesSelector(state);
    const followedSellerIds = getFollowedSellerIds(state);
    return Promise.all([
        dispatch(fetchHomeDataIfNeeded(false)),
        dispatch(fetchUpcomingItemsIfNeeded()),
        dispatch(fetchSavedSearchesIfNeeded()),
        dispatch(fetchSavedSearchItemsIfNeeded(hasSavedSearches)),
        dispatch(fetchRecommendedItemIfNeeded(false)),
        ...followedSellerIds.map((sellerId) => dispatch(fetchSellerUpcomingCatalogsIfNeeded(sellerId))),
    ]).then(() => {
        const newState = getState();
        const isLoggedIn = isUserLoggedIn(newState);
        const promises = [];

        if (isLoggedIn) {
            const upcomingItemIds = getUpcomingItemIds(newState);
            const savedSearchItemIds = getSavedSearchItemIds(newState);
            const recommendedItemIds = getSortedRecommendedItemIdsFromState(newState, 12);
            promises.push(
                dispatch(
                    fetchItemSummariesIfNeeded({
                        identifier: 'COMING-UP-Carousel',
                        itemIds: upcomingItemIds,
                    })
                )
            );
            promises.push(
                dispatch(
                    fetchItemSummariesIfNeeded({
                        identifier: 'FOLLOWED-SEARCH-Carousel',
                        itemIds: savedSearchItemIds,
                    })
                )
            );
            promises.push(
                dispatch(
                    fetchItemSummariesIfNeeded({
                        identifier: 'RECOMMENDED-ITEM-Carousel',
                        itemIds: recommendedItemIds,
                    })
                )
            );
            promises.push(dispatch(fetchWonItemsForBannerIfNeeded({ featureFlagVariants })));
        }

        return Promise.all(promises);
    });
};
