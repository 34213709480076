import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

export enum MyFavoritesItemStatusTab {
    Available = 'available',
    Ended = 'ended',
}

export type MyFavoritesPageQueryParams = {
    keyword?: string;
    page?: number;
    pageSize?: 24 | 48 | 72 | 96 | 120;
    sort?: SearchSortAndDirection;
    status?: MyFavoritesItemStatusTab;
};
