import { ConversationQueryType } from '@/redux/modules/conversation/conversations/conversation.types';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchConversationFoldersIfNeeded } from '@/redux/modules/conversation/folders/conversationFolders.actions';
import { fetchConversationIfNeeded } from '@/redux/modules/conversation/conversations/conversation.actions';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchSellerFollowerCountIfNeeded } from '@/redux/modules/sellerFollowerCount';
import { fetchSellerRatingsByIdIfNeeded } from '@/redux/modules/sellerRatings';
import { fetchSellersDetailsIfNeeded } from '@/redux/modules/sellerDetail';
import { fetchSellersIfNeeded } from '@/redux/modules/seller';
import { fetchTopSellersIfNeeded } from '@/redux/modules/topRatedSellers';
import { getConversation } from '@/redux/modules/conversation/conversations/conversation.selectors';
import { loadConversationMessages } from '@/redux/modules/conversation/messages/conversationMessages.actions';
import { loadConversationPageData } from '@/redux/modules/conversation/page/conversationPage.actions';
import { parseQueryParams } from '@/utils/queryParams';

export default async ({ location, match, store }: DataLoaderParams) => {
    const queryParams: ConversationQueryType = parseQueryParams(location.search);
    const { params } = match;
    const { dispatch, getState } = store;
    const conversationId = Number(params.conversationId);

    const promises: Promise<unknown>[] = [
        dispatch(fetchTopSellersIfNeeded()),
        dispatch(fetchCatalogAdIfNeeded({ pageName: 'leaderboard-messages' })),
    ];

    if (conversationId) {
        promises.push(dispatch(loadConversationMessages(conversationId)));
        promises.push(dispatch(fetchConversationIfNeeded(conversationId)));
    } else {
        return Promise.resolve();
    }

    await Promise.all(promises);

    const secondaryPromises = [];
    const secondaryState = getState();
    const { catalogId, itemId, read, sellerId } = getConversation(secondaryState, conversationId);

    if (!read) {
        secondaryPromises.push(
            dispatch(
                loadConversationPageData({
                    action: 'markRead',
                    conversationIds: [conversationId],
                    markOnly: true,
                    query: queryParams,
                })
            )
        );
    } else {
        secondaryPromises.push(dispatch(fetchConversationFoldersIfNeeded({})));
    }

    if (sellerId) {
        secondaryPromises.push(dispatch(fetchSellersIfNeeded([sellerId])));
        secondaryPromises.push(dispatch(fetchSellersDetailsIfNeeded([sellerId])));
        secondaryPromises.push(dispatch(fetchSellerRatingsByIdIfNeeded({ sellerId })));
        secondaryPromises.push(dispatch(fetchSellerFollowerCountIfNeeded([sellerId])));
    }

    if (catalogId) {
        secondaryPromises.push(dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] })));
    }

    if (itemId) {
        secondaryPromises.push(
            dispatch(
                fetchItemSummariesIfNeeded({
                    identifier: 'MESSENGER-PAGE-DATA-LOADER',
                    itemIds: [itemId],
                })
            )
        );
    }
    return Promise.all(secondaryPromises);
};
