import { DataLoaderParams } from '@/types/DataLoaderParams';
import { defaultOtherCategoriesPageSize } from '@/pages/DiscoverCategories/components/DiscoverCategories/components/OtherCategories/constants/otherCategoriesConstants';
import { fetchAllCategoriesLandingPageData } from '@/redux/modules/category/category.actions';
import { fetchOtherCategoriesIfNeeded } from '@/redux/modules/otherCategories/otherCategories';

const discoverCategoriesLoadData = ({ store }: DataLoaderParams) => {
    const { dispatch } = store;

    const promises = [
        dispatch(fetchAllCategoriesLandingPageData()),
        dispatch(fetchOtherCategoriesIfNeeded(1, defaultOtherCategoriesPageSize)),
    ];

    return Promise.all(promises).then(() => {});
};

export default discoverCategoriesLoadData;
