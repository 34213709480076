import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCuratedCollectionData, getCuratedCollectionItemIds } from '@/redux/modules/curatedCollection';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { getNotFoundUrl } from '@/utils/urls';

export default ({ match, redirect, store }: DataLoaderParams) => {
    const { params } = match;

    if (!params.collectionId || !params.collectionName) {
        return redirect(getNotFoundUrl());
    }

    const { dispatch, getState } = store;

    return Promise.all([dispatch(fetchCuratedCollectionData(Number(params.collectionId)))]).then(() => {
        const currentState = getState();
        const itemIds = getCuratedCollectionItemIds(currentState);
        return dispatch(
            fetchItemSummariesIfNeeded({
                identifier: 'CURATED-COLLECTION-PAGE-LOADER',
                itemIds,
            })
        );
    });
};
