import {
    UserBidPlacedItemsBidStatusEnum,
    UserBidPlacedItemsSortEnum,
    UserBidPlacedItemsStatusEnum,
} from '@/types/item/UserBidPlacedItem';
import type { UserBidPlacedItemsParams } from '@/redux/modules/item/userBidPlacedItems/userBidPlacedItems.types';

// supported url params for the my-bids page
export type MyBidsPageParams = {
    filter?: UserBidPlacedItemsBidStatusEnum;
    page?: number;
    pageSize?: 24 | 48 | 72 | 96 | 120;
    sort?: UserBidPlacedItemsSortEnum;
    tab?: UserBidPlacedItemsStatusEnum;
    terms?: string;
};

/**
 * Accepts all page params as an input, and returns an object of valid params to be used for data fetching
 *
 * This is intended to handle cases where the params are incorrect or missing
 */
const validateMyBidsPageParams = (params: MyBidsPageParams): UserBidPlacedItemsParams => {
    // create default values for api params (derived from url params)
    let bidStatus: UserBidPlacedItemsParams['bidStatus'] = UserBidPlacedItemsBidStatusEnum.ALL;
    let keyword: UserBidPlacedItemsParams['keyword'] = '';
    let page: UserBidPlacedItemsParams['page'] = 1;
    let pageSize: UserBidPlacedItemsParams['pageSize'] = 24;
    let sort: UserBidPlacedItemsParams['sort'] = UserBidPlacedItemsSortEnum.SALE_START_ASC;
    let status: UserBidPlacedItemsParams['status'] = UserBidPlacedItemsStatusEnum.UPCOMING;

    // if url params are present (and valid), use to fetch api
    // the url param is called filter, but it represents bid status
    if (params?.filter && Object.values(UserBidPlacedItemsBidStatusEnum).includes(params.filter)) {
        bidStatus = params.filter;
    }

    if (params?.terms && typeof params.terms === 'string') {
        keyword = params.terms;
    }

    if (params?.page && typeof params.page === 'number') {
        page = params.page;
    }

    if (params?.pageSize && typeof params.pageSize === 'number') {
        pageSize = params.pageSize;
    }

    if (params?.sort && Object.values(UserBidPlacedItemsSortEnum).includes(params.sort)) {
        sort = params.sort;
    }

    if (params?.tab && Object.values(UserBidPlacedItemsStatusEnum).includes(params.tab)) {
        status = params.tab;
    }

    return {
        bidStatus,
        keyword,
        page,
        pageSize,
        sort,
        status,
    };
};

export default validateMyBidsPageParams;
