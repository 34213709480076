import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchDataForAuctionCalendarPage } from '@/redux/modules/auctionCalendar/auctionCalendar.actions';
import { getSearchPageSize } from '@/redux/modules/pagination';
import { parseQueryParams } from '@/utils/queryParams';

export default ({ location, store }: DataLoaderParams) => {
    const { dispatch, getState } = store;
    const state = getState();
    const pageSize = getSearchPageSize(state);
    const queryParams: any = parseQueryParams(location.search);
    queryParams.pageSize = pageSize; // ignore pageSize in the url

    return dispatch(fetchDataForAuctionCalendarPage(queryParams));
};
