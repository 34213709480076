import * as React from 'react';
import { checkForUnpaidSentInvoices } from '@/redux/modules/wonItems/wonItems.actions';
import { getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';

/**
 * Fetches any route preload data while changing to that route on the client-side
 * @see /src/server/ssr.js for server-side implementation
 */

const CYPRESS_BIDDER_ID = 2894878;

const UnpaidInvoiceCheck = () => {
    // check if they have an invoice that's been sent but not paid
    const dispatch = useAppDispatch();
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const wasLoggedIn = usePrevious(userLoggedIn);
    const bidderId = useAppSelector(getBidderId);

    // if a bidder logs in then we should kick off this call,
    // this will also kick off if the bidder is logged in when this loads which is expected
    React.useEffect(() => {
        if (!wasLoggedIn && userLoggedIn && bidderId !== CYPRESS_BIDDER_ID) {
            // fire off do they owe us money call
            dispatch(checkForUnpaidSentInvoices(bidderId));
        }
    }, [userLoggedIn, bidderId, dispatch, wasLoggedIn]);

    return null;
};

export default UnpaidInvoiceCheck;
